@font-face {
  font-family: "Myriad Pro";
  src: url("../fonts/MyriadPro-Light.eot");
  src: url("../fonts/MyriadPro-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MyriadPro-Light.woff2") format("woff2"),
    url("../fonts/MyriadPro-Light.woff") format("woff"),
    url("../fonts/MyriadPro-Light.ttf") format("truetype"),
    url("../fonts/MyriadPro-Light.svg#svgFontName") format("svg");
  font-weight: 300;
}
@font-face {
  font-family: "Yukita Sans";
  src: url("../fonts/YukitaSans-Light.eot");
  src: url("../fonts/YukitaSans-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/YukitaSans-Light.woff2") format("woff2"),
    url("../fonts/YukitaSans-Light.woff") format("woff"),
    url("../fonts/YukitaSans-Light.ttf") format("truetype"),
    url("../fonts/YukitaSans-Light.svg#svgFontName") format("svg");
  font-weight: 300;
}
@font-face {
  font-family: "Yukita Sans";
  src: url("../fonts/YukitaSans-Bold.eot");
  src: url("../fonts/YukitaSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/YukitaSans-Bold.woff2") format("woff2"),
    url("../fonts/YukitaSans-Bold.woff") format("woff"),
    url("../fonts/YukitaSans-Bold.ttf") format("truetype"),
    url("../fonts/YukitaSans-Bold.svg#svgFontName") format("svg");
  font-weight: bold;
}
@font-face {
  font-family: "UKNumberPlate";
  src: url("../fonts/UKNumberPlate.eot");
  src: url("../fonts/UKNumberPlate.eot?#iefix") format("embedded-opentype"),
    url("../fonts/UKNumberPlate.woff2") format("woff2"),
    url("../fonts/UKNumberPlate.woff") format("woff"),
    url("../fonts/UKNumberPlate.ttf") format("truetype"),
    url("../fonts/UKNumberPlate.svg#svgFontName") format("svg");
  font-weight: 400;
}
@font-face {
  font-family: "Silka";
  src: url("../fonts/Silka-Regular.eot");
  src: url("../fonts/Silka-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Silka-Regular.woff2") format("woff2"),
    url("../fonts/Silka-Regular.woff") format("woff"),
    url("../fonts/Silka-Regular.ttf") format("truetype"),
    url("../fonts/Silka-Regular.svg#svgFontName") format("svg");
  font-weight: 400;
}
@font-face {
  font-family: "Myriad Pro";
  src: url("../fonts/MyriadPro-Bold.eot");
  src: url("../fonts/MyriadPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/MyriadPro-Bold.woff2") format("woff2"),
    url("../fonts/MyriadPro-Bold.woff") format("woff"),
    url("../fonts/MyriadPro-Bold.ttf") format("truetype"),
    url("../fonts/MyriadPro-Bold.svg#svgFontName") format("svg");
  font-weight: 700;
}
@font-face {
  font-family: 'Silka';
  src: url('../fonts/Silka-Bold.eot');
  src: url('../fonts/Silka-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Silka-Bold.woff2') format('woff2'),
      url('../fonts/Silka-Bold.woff') format('woff'),
      url('../fonts/Silka-Bold.ttf') format('truetype'),
      url('../fonts/Silka-Bold.svg#Silka-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


* {
  outline: none !important;
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  font-weight: 400;
  overflow-x: hidden;
}

.headermenu {
  overflow: hidden;
}
img {
  max-width: 100%;
}
body {
  counter-reset: section;
  box-sizing: border-box;
  font-size: 16px;
}
body *,
body *:before,
body *:after {
  box-sizing: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}
ul {
  list-style: none;
}
ul#pagination {
  gap: 5px;
  height: 58px;
  margin-top: 76px;
}
ul#pagination li a {
  padding: 13px 23px 15px 23px;
  border-radius: 7.5px;
  font-size: 18px;
}
ul#pagination li a.active {
  background-color: #00f5d0;
}
ul#pagination li a {
  color: #333;
}
.primary-color {
  color: #007bff;
}
.secondry-color {
  color: #00f5d0;
}
.tertiary-color {
  color: #00344a;
}
::placeholder {
  color: #b5b5b5;
}
.yellow-color {
  color: #f5ec33;
}
.row-gap-10 {
  row-gap: 10px;
}
.row-gap-24 {
  row-gap: 24px;
}

.col-gap-10 {
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.section-spacing {
  padding: 60px 0;
}

.text-green {
  color: #13f6c1;
}
.green-section {
  background-color: #13f6c1;
}

.w-100 {
  width: 100%;
}

.gradiant-text {
  background: var(
    --Blue-Icon,
    linear-gradient(180deg, #3b82f6 0%, #18acff 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.form-group {
  margin-bottom: 10px;
}
.form-group label {
  font-size: 12px;
}

.w-100 {
  width: 100%;
}

.page__content {
  flex: 1;
}

.color-black {
  color: #000000 !important;
}

.color-white {
  color: #ffffff !important;
}

.color-light-gray {
  color: #6a6a6a;
}

.color-black {
  color: #000000 !important;
}

.bgcolor-black {
  background-color: #000000 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.container {
  width: 100%;
  max-width: 1344px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

ul {
  padding-left: 0;
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #111111;
  transition: all 0.5s ease-in-out;
}
a:hover {
  color: #3b82f6;
  transition: all 0.5s ease-in-out;
}

.hide {
  display: none;
}

input,
button {
  font-family: inherit;
}

.fys {
  font-family: "Yukita Sans", "sans-serif";
}

.fmp {
  font-family: "Myriad Pro", "sans-serif";
}

.fsi {
  font-family: "Silka", "sans-serif";
}

.fun {
  font-family: "UKNumberPlate", "sans-serif";
}
.price.fun.text-300 {
  font-family: "Yukita Sans", "sans-serif";
}

.h1-font {
  font-size: 34px !important;
}

.sixteen-font {
  font-size: 16px !important;
  line-height: 24px !important;
}

.mt-26 {
  margin-top: 26px !important;
}
h1,
.f60 {
  font-size: 60px;
}

h2,
.f40 {
  font-size: 40px;
}

.f36 {
  font-size: 26px;
}

h3,
.f32 {
  font-size: 32px;
}

h4,
.f30 {
  font-size: 30px;
}

h5,
.f24 {
  font-size: 24px;
}

a,
.f20 {
  font-size: 20px;
}

h6,
.f28 {
  font-size: 28px;
}

.f18 {
  font-size: 18px;
}

p,
.f16 {
  font-size: 16px;
}

span,
.f-14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}
.f12 {
  font-size: 12px;
}

.lh14 {
  line-height: 14px;
}

.lh20 {
  line-height: 20px;
}

.lh22 {
  line-height: 22px;
}

.lh24 {
  line-height: 24px;
}

.lh28 {
  line-height: 28px;
}

.lh32 {
  line-height: 32px;
}

.lh30 {
  line-height: 30px;
}

.lh45 {
  line-height: 45px;
}

.lh60 {
  line-height: 60px;
}

.lh150 {
  line-height: 1.5;
}

.text-100 {
  font-weight: 100;
}

.text-200 {
  font-weight: 200;
}

.text-300 {
  font-weight: 300;
}

.text-400 {
  font-weight: 400;
}

.text-500 {
  font-weight: 500;
}

.text-600 {
  font-weight: 600;
}

.text-700 {
  font-weight: 700;
}

.text-bold {
  font-weight: bold;
}

.text-blue {
  color: #437dc0;
}

.text-white {
  color: #ffffff;
}

.header-text {
  color: #1a3057;
}

.black-text {
  color: #111111;
}

.blue-text {
  color: #3b82f6;
}

.gray-text {
  color: #aab3bb;
}
.silver-text {
  color: #999999;
}

.yellow-bg {
  background-color: #f5ec33;
}

.header-bg {
  background-color: rgba(67, 125, 192, 0.4);
}

input:focus {
  outline: none;
  border: 0;
  box-shadow: none !important;
}

button:focus:not(:focus-visible) {
  box-shadow: none !important;
}

button img {
  padding: 4px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-45 {
  margin-top: 45px;
}
.mb-23 {
  margin-bottom: 23px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-52 {
  margin-bottom: 52px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-5 {
  margin-left: 5px;
}
.ml-15 {
  margin-left: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-25 {
  margin-left: 25px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mtb-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.p-0 {
  padding: 0 !important;
}

.p16 {
  padding: 16px;
}

.p-32 {
  padding: 32px;
}

.p-40 {
  padding: 40px;
}
.p-45 {
  padding: 45px;
}

.pr-5 {
  padding-right: 5px;
}

.p15 {
  padding: 15px;
}

.pr-40 {
  padding-right: 40px;
}

.pl-9 {
  padding-left: 9px;
}

.pl-16 {
  padding-left: 16px;
}

.pr-21 {
  padding-right: 21px;
}

.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}

.pr-45 {
  padding-right: 45px;
}

/* .px-0 {
  padding-top: 0px;
  padding-bottom: 0px;
} */
.pt-45 {
  padding-top: 45px;
}

.pt-55 {
  padding-top: 55px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}

.pb-20 {
  padding-bottom: 20px;
}
.disc {
  list-style-type: disc;
}
.w-32 {
  width: 32px;
}

.h-32 {
  height: 32px;
}

.border-color {
  border-color: #232323 !important;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.br-1 {
  border-right-width: 1px;
}

.br-2 {
  border-right-width: 2px !important;
}

.b-full {
  border-radius: 100%;
}

.br20 {
  border-radius: 20px;
}

.br4 {
  border-radius: 4px;
}

section {
  overflow: hidden;
}

/* -------Common class---- */
.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-flex_wrap {
  flex-wrap: wrap;
}

.align-self-center {
  align-self: center;
}

.center {
  text-align: center;
}

.text-upper {
  text-transform: uppercase;
}

.my-auto {
  margin: 0 auto;
}

.z-1 {
  z-index: 1;
}

.z-n1 {
  z-index: -1;
}

.reg__review--data .col-2 {
  width: 18%;
}
.reg__review--data .col-10 {
  width: 82%;
}

.medium-gn {
  color: #275e5f;
}

.right-line {
  position: relative;
}
.right-line::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 1px;
  height: 15px;
  transform: translateY(-50%);
  background-color: #efefef;
}

.reg__reviewer::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
.reg__reviewer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
.reg__reviewer::-webkit-scrollbar-thumb {
  background: #437dc0;
  border-radius: 10px;
}

.bg-image {
  position: relative;
}
.bg-image::before {
  content: "";
  position: absolute;
  border: 25.5px solid red;
  -o-border-image: url(../images/fbgdesign.svg) 25.5;
  border-image: url(../images/fbgdesign.svg) 25.5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.fsubmit_btn {
  position: relative;
  padding: 0;
}
.fsubmit_btn button {
  position: relative;
  padding: 25px 15px;
  width: 100%;
  z-index: 2;
}

.fsubmit_btn button::before {
  content: "";
  position: absolute;
  /* border: 33.5px solid #13f6c1; */
  border: 33.5px solid #13f6c1;
  -o-border-image: url(../images/fsubbtn.svg) 35.5;
  border-image: url(../images/fsubbtn.svg) 35.5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}
.fsubmit_btn button:hover::before {
  border: 2px solid #13f6c1;
  -o-border-image: none;
  border-image: none;
  transition: all 0.5s ease-in-out;
}

.reg__buy--button {
  position: relative;
  padding: 0;
  background-color: transparent;
  margin-bottom: 20px !important;
}

.enquire-btn a,
.offer-btn a,
.offer-btn button,
.buy-btn a {
  position: relative;
  padding: 18px 15px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}
.enquire-btn a::before,
.offer-btn a::before,
.offer-btn button::before,
.buy-btn a::before {
  content: "";
  position: absolute;
  border: 25.1px solid #b4cbe6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.enquire-btn a::before {
  -o-border-image: url(../images/eqbg.svg) 25;
  border-image: url(../images/eqbg.svg) 25;
  transition: all 0.5s ease-in-out;
}
.enquire-btn a:hover {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}
.enquire-btn a:hover::before {
  border: 1px solid #b4cbe6;
  transition: all 0.5s ease-in-out;
}

.offer-btn a::before {
  border: 25.1px solid #1a3057;
  -o-border-image: url(../images/mobg.svg) 25;
  border-image: url(../images/mobg.svg) 25;
}
.offer-btn button::before {
  border: 30px solid #1a3057;
  -o-border-image: url(../images/mobg.svg) 33;
  border-image: url(../images/mobg.svg) 33;
}
.offer-btn button:hover,
.offer-btn a:hover {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
  color: #1a3057 !important;
}
.offer-btn button:hover svg path,
.offer-btn a:hover svg path {
  stroke: #1a3057;
}
.offer-btn button:hover::before,
.offer-btn a:hover::before {
  border: 1px solid #1a3057;
  transition: all 0.5s ease-in-out;
}

.buy-btn a::before {
  border: 25.1px solid #13f6c1;
  -o-border-image: url(../images/buybg.svg) 25;
  border-image: url(../images/buybg.svg) 25;
}
.buy-btn a:hover {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}
.buy-btn a:hover::before {
  border: 1px solid #13f6c1;
  transition: all 0.5s ease-in-out;
}

.green-image {
  /* background-image: url(../images/fgreendesign.svg);
background-position: left center;
background-repeat: no-repeat;
background-size: 150px;
-webkit-border-bottom-left-radius: 22px !important;
border-bottom-left-radius: 16px !important;
border-top-left-radius: 12px !important;
border-left: 2px solid #ffffff !important; */
  background: #00f5d0 !important;
  border-radius: 0 !important;
  z-index: 0;
  clip-path: path(
    "M845.4,12.3L831.7,1.2c-1-0.8-2.2-1.2-3.5-1.2H552.7H283.5H13.1C5.9,0,0,5.9,0,13.1V41c0.1,2.9,2.9,4.2,4.9,6 l6.4,5c1.8,1.1,3.2,2.4,4.8,3.8L288.7,56h273.8h277.4c4.2,0,7.6-3.4,7.6-7.6V16.7C847.5,15,846.7,13.4,845.4,12.3z"
  );
}
@media (max-width: 1343px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .pt-45 {
    padding-top: 25px;
  }
  .pb-45 {
    padding-bottom: 25px;
  }
  .reg__reviewer::-webkit-scrollbar {
    width: 10px;
    height: 0px;
  }
  /* Track */
  .reg__reviewer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }
  /* Handle */
  .reg__reviewer::-webkit-scrollbar-thumb {
    background: #437dc0;
    border-radius: 10px;
  }
  header .header-text {
    color: #ffffff;
  }

  .dropdown .dropdown-menu {
    position: static !important;
    padding-top: 0;
    background-color: transparent;
    border: none;
  }
  .dropdown .dropdown-menu a {
    color: #ffffff !important;
  }
}
.reg__footer {
  background-color: #1a3057;
  padding: 40px 0;
}
.reg__footer .reg__dmo--section .row > .col-6 {
  padding: 0;
}
.reg__footer .reg__partition {
  width: 100%;
  height: 1px;
  margin: 35px 0;
  background-color: #efefef;
}
.reg__footer .textBanner__stars svg path {
  fill: #0ed90e;
}
.reg__footer .reg__retting--data {
  padding: 22px 30px;
  background-color: #232323;
}
.reg__footer .reg__help--data ul li {
  list-style-type: none;
}
.reg__footer a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.reg__footer a:hover {
  color: #0ed90e;
}

.overlay {
  overflow: hidden;
}

.header {
  position: relative;
  z-index: 999999;
}
.header .navbar-toggler-icon {
  width: 2em;
  height: 2em;
}
.header #navbarSupportedContent {
  display: block;
}
.header .dropdown .nav-link::after {
  background-image: url(../images/menuarrow.svg);
  background-size: 100% 100%;
  width: 14px;
  height: 14px;
  border: none;
  vertical-align: text-top;
  margin-left: 0.7em;
}

.hero-number img {
  width: 24px !important;
  height: 24px !important;
}
/* .header .reg__search--button img {
  padding: 5px;
} */
.header input {
  width: 600px;
  border-left: 0;
  background-color: transparent;
}
.reg__buypage form input{
  /* width: 600px; */
  border-left: 0;
  background-color: transparent;
}
.reg__buypage .reg__search--button,
.header .reg__search--button {
  padding: 8px 15px;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 25% !important;
  background-color: #00f5d0;
}
/* .header .reg__search--button:hover {
  background-color: #a3ffe9 !important;
} */
.header .reg__call--image img {
  width: 32px;
  height: 32px;
  padding: 4px;
}
.reg__buypage form,
.header form {
  position: relative;
  margin-right: 15px;
}
.header .navbar-nav li .nav-link:hover {
  color: #0a58ca !important;
}
.header .navbar-nav li .dropdown-menu a:hover {
  color: #1e2125 !important;
  background-color: #e9ecef !important;
}
.reg__buypage form::before,
.header form::before {
  content: "";
  position: absolute;
  background-image: url("../images/mask.png");
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
}
.header .navbar-toggler {
  display: none;
  border: none;
}
.header .navbar-toggler:focus {
  box-shadow: 0 0 0 0;
}

@media (max-width: 1199px) {
  .header input {
    width: 450px;
  }
  .header .reg__search--button {
    border-top-right-radius: 40% !important;
  }
  .header .pl-40 {
    padding-left: 15px;
  }
  .header .pr-40 {
    padding-right: 15px;
  }
}
@media (max-width: 991px) {
  .reg__call--details a {
    font-size: 16px;
  }
  .header .navbar-toggler {
    display: block;
  }
  .header input {
    width: 250px;
  }
  .header form {
    display: none !important;
  }
  .header .reg__call--details span {
    display: none !important;
  }
  .header .navbar-nav li .dropdown-menu a:hover {
    color: #dcdcdc !important;
    background-color: transparent !important;
  }
  .header .navbar-nav li .nav-link:hover {
    color: #fff !important;
  }
}
@media (max-width: 767px) {
  .reg__call--details a {
    font-size: 14px;
  }
  .reg__registrations--section .reg__plate--data h1 {
    font-size: 27px;
  }
}
@media (max-width: 1200px) {
  .reg__registrations--section .reg__plate--data h1 {
    font-size: 41px;
  }
}
@media (min-width: 1200px) {
  .reg__registrations--section .reg__plate--data h2 {
    font-size: 56px;
  }
}
@media (max-width: 561px) {
  .navbar-brand img {
    width: 157px;
  }
  .header .navbar-toggler {
    display: block;
    padding: 0;
  }
  a.navbar-brand {
    margin-right: 6px;
  }
}
@media (max-width: 400px) {
  .header .navbar-brand img {
    width: 100px;
  }
  .header .reg__call--image img {
    width: 22px;
    height: 22px;
    padding: 2px;
  }
  .reg__registrations--section .reg__plate--data h1 {
    font-size: 21px;
  }
}
.reg__hero--section {
  /* background-image: url("../images/heroimage2.webp"); */
  min-height: 695px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.reg__hero--section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 3, 53, 0.6588235294);
  z-index: 0;
}
.reg__hero--section .reg__car--image {
  z-index: 2;
  position: relative;
  text-align: center;
  /* padding: 40px; */
}
.reg__hero--section .reg__car--detailss {
  background-color: red;
  background: rgba(0, 0, 0, 0.8);
  padding: 32px 48px;
  z-index: 2;
  position: relative;
  text-align: center;
}
.reg__car--title h4 {
  text-align: start;
  line-height: 30px;
  margin-bottom: 15px;
}
.reg__hero--section .reg__car--title form {
  background-image: url(../images/whitebg.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
  border-top-left-radius: 10px;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  background-size: 780px;
}
.reg__hero--section .reg__car--title input {
  background-color: transparent;
  border: 0;
  /* color: #8F8F8F; */
  font-weight: 400;
  height: 70px;
  padding: 8px 20px !important;
}
.reg__hero--section .reg__car--title button {
  background-color: transparent;
  background-image: url(../images/searchbg.svg);
  background-repeat: no-repeat;
  background-position: top right;
  /* background-size: 100% 100%; */
  background-size: 84px;
}
.reg__hero--section .reg__car--title svg {
  margin-right: 8px;
}
.reg__hero--section .reg__buy--button button {
  background-image: none;
  border: 0;
  padding: 18px 25px;
  position: relative;
  transition: all 0.5s ease-in-out;
  line-height: 1;
  color: #000000;
}
.reg__hero--section .reg__buy--button button svg path {
  transition: all 0.5s ease-in-out;
}
.reg__hero--section .reg__buy--button button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  border: 26px solid #13f6c1;
  -o-border-image: url(../images/buybg.svg) 25;
  border-image: url(../images/buybg.svg) 25;
  transition: all 0.5s ease-in-out;
}
.reg__hero--section .reg__buy--button button:hover {
  transition: all 0.5s ease-in-out;
  color: #ffffff;
}
.reg__hero--section .reg__buy--button button:hover svg path {
  fill: #ffffff;
  transition: all 0.5s ease-in-out;
}
.reg__hero--section .reg__buy--button button:hover::before {
  border: 2px solid #13f6c1;
  -o-border-image: none;
  border-image: none;
  transition: all 0.5s ease-in-out;
}
.reg__hero--section .reg__buy--button button.buy {
  min-width: 165px;
  line-height: 0.9;
}
.reg__hero--section .reg__buy--button button.buy:hover {
  color: white;
}
.reg__hero--section .reg__buy--button button.sell {
  min-width: 180px;
  line-height: 0.9;
}
.reg__hero--section .reg__buy--button button.sell:hover {
  color: white;
}
.reg__hero--section .reg__buy--button button svg {
  margin-left: 36px;
  margin-right: 0px;
}
.reg__hero--section .reg__buy--button button + button {
  margin-left: 24px;
}

.reg__review--section {
  margin-top: -70px;
  position: relative;
  z-index: 9999;
}
.reg__review--section .reg__review--data {
  padding: 10px 40px 21px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
}

.reg__reviewer {
  overflow: auto;
}

.reg__reviewer--data {
  min-width: 991px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.reg__reviewer--star-image svg {
  width: -moz-fit-content;
  width: fit-content;
}

.car-image {
  padding: 0 32px;
}

.reg__plate--data {
  padding: 15px;
  text-align: center;
}

.reg__number--plate .col-3 {
  padding: 0 8px;
}

.reg__whychoose--section {
  padding: 40px 0;
}

.reg__choose--data-box {
  background: #fff;
  box-shadow: 0 0px 29px 5px #64646f33;
  padding: 32px 30px 22px;
  transition: all 0.5s ease-in-out;
  height: 100%;
}
.reg__choose--data-box:hover {
  box-shadow: none;
  transition: all 0.5s ease-in-out;
}
.reg__data--text a {
  color: #4ea6fe;
}
.reg__choose--data-box .reg__data--image {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bedcf3;
  border-radius: 40px;
}
.reg__image--posion {
  display: none;
}
.reg__choose--data-box .reg__data--text {
  flex: 1;
  margin-left: 20px;
}
.reg__choose--data-box .reg__choose--text {
  padding-right: 100px;
}

.reg__choose--text.reg__choose--data-box {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.reg__whychoose--section {
  background-color: #f9f9f9;
}

.reg__registrations--section {
  padding-bottom: 10px;
}

.reg__help--title {
  background-color: #00f5d0;
  padding: 40px 15px;
  max-width: 40%;
  flex: 0 0 40%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .reg__list--data {
  background-color: #f9f9f9;
} */
.reg__help--contect {
  padding: 24px 48px;
}
.reg__help--contect .reg__tel img {
  height: 40px;
  width: 40px;
}
.reg__help--contect .reg__tel svg {
  height: 40px;
  width: 40px;
}
.reg__help--contect .reg__tel svg path {
  stroke: #111111;
}
.reg__help--contect .reg__tel .teg__tel--text {
  margin-left: 8px;
  font-family: "Yukita Sans", "sans-serif";
}
.reg__help--contect .reg__tel + .reg__tel {
  margin-left: 64px;
}
.reg__help--contect .reg__social--icon {
  margin-top: 5px;
}
.reg__help--contect .reg__social--icon a + a {
  margin-left: 10px;
}
.reg__help--contect .reg__social--icon svg path {
  transition: all 0.5s ease-in-out;
}
.reg__help--contect .reg__social--icon svg:hover path {
  transition: all 0.5s ease-in-out;
  fill: #1d437c;
}

.reg__dmo--section .row {
  align-items: center;
}

.reg__search--button {
  display: flex;
  align-items: center;
  color: #111;
  font-size: 13px;
  font-weight: 400;
}
.reg__footer--form {
  background-image: url(../images/footerwhitebg.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 300px 315px;
  margin: 0 auto;
  width: -moz-fit-content;
  background-color: transparent !important;
  width: -webkit-fit-content;
  width: fit-content;
}
.reg__footer--form input {
  background-color: transparent !important;
  width: 260px;
  border: none;
}
.reg__footer--form button {
  background-color: transparent;
  background-image: url(../images/footerbtbg.svg);
  background-position: 100% 100%;
  border-radius: 0 12px 10px 0 !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.reg__footer--form .d-flex.justify-between.mb-23 {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.reg__footer .reg__search--button:hover {
  color: #1d437c;
}

.reg__slider--button {
  display: none;
}

@media (max-width: 1400px) {
  .container {
    padding: 0 15px;
  }
}
@media (max-width: 1366px) {
  /* .container {
    padding: 0 15px;
  } */
  .reg__help--contect {
    padding: 24px 22px;
  }
  .reg__help--contect .reg__tel + .reg__tel {
    margin-left: 30px;
  }
}
@media (max-width: 1080px) {
  .reg__help--section {
    flex-wrap: wrap;
  }
  .reg__help--title,
  .col-7 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .reg__retting {
    flex-wrap: wrap;
  }
  /* .reg__retting .reg__logo {
    margin-bottom: 33px;
  } */
  .reg__retting .reg__retting--data {
    width: 100%;
  }
  .reg__help--data ul + ul {
    padding-left: 15px;
  }
}
@media (max-width: 991px) {
  h2,
  .f40 {
    font-size: 32px;
  }
  .lh60 {
    line-height: 48px;
  }
  .reg__hero--section {
    min-height: 695px;
    padding: 100px 0;
  }
  .reg__hero--section .row {
    flex-direction: column;
  }
  .reg__hero--section .row > .col-6 {
    order: 2;
  }
  .reg__hero--section .row > .col-6 + .col-6 {
    order: 1;
  }
  .reg__hero--section .row .col-6 {
    width: 70%;
  }
  .reg__hero--section .reg__car--detailss {
    padding: 36px 26px;
  }
  .reg__review--section .reg__excelent {
    margin-bottom: 35px;
  }
  .reg__review--section .reg__review--data {
    padding: 50px 40px 10px;
  }
  .reg__review--section .reg__review--data .row {
    justify-content: center;
  }
  .reg__review--section .reg__review--data .col-2 {
    width: 240px;
  }
  .reg__review--section .reg__review--data .col-10 {
    width: 100%;
  }
  .reg__reviewer {
    display: none;
  }
  .reg__dvla--section .car-image {
    text-align: center;
    margin-bottom: 25px;
  }
  .reg__dvla--section .col-4,
  .reg__dvla--section .col-8 {
    width: 100%;
  }
  .car--data h4 br {
    display: none;
  }
  .car--data .car-inner {
    display: block !important;
  }
  .car--data .car-inner p + p {
    margin-top: 28px;
  }
  .car--data .car-inner .pl-16 {
    padding-left: 0px;
  }
  .reg__slider--button {
    display: block;
  }
  .reg__number--plate .row {
    margin: 0;
  }
  .reg__number--plate .col-3 {
    padding: 0 0px;
  }
  .reg__number--plate .swiper-wrapper {
    flex-wrap: nowrap;
  }
  .regswiper {
    position: relative;
  }
  .regswiper .reg__slider--button {
    position: relative;
    display: flex;
    margin-top: 20px;
  }
  .regswiper .reg__slider--button .swiper-button-next,
  .regswiper .reg__slider--button .swiper-button-prev {
    position: static;
    margin-right: 25px !important;
  }
  .regswiper .reg__slider--button .swiper-button-next,
  .regswiper .reg__slider--button .swiper-button-prev {
    width: 48px;
    height: 48px;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    margin: 0;
  }
  .regswiper .reg__slider--button .swiper-button-next::after,
  .regswiper .reg__slider--button .swiper-button-prev::after {
    font-size: 0;
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .regswiper .reg__slider--button .swiper-button-next::after {
    background-image: url(../images/sliderright.svg);
  }
  .regswiper .reg__slider--button .swiper-button-prev::after {
    background-image: url(../images/sliderleft.svg);
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    font-size: 18px;
    background: #fff;
  }
  .reg__retting {
    flex-wrap: wrap;
    row-gap: 30px;
  }
  .reg__retting .f18 {
    font-size: 15px;
  }
  .reg__retting .reg__retting--data {
    padding: 22px 5px;
    width: 100%;
    position: relative;
  }
  .reg__retting .reg__retting--data::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #1a3057;
    transform: translateY(-50%);
  }
  .reg__retting .reg__retting--data > a {
    width: 100%;
  }
  .reg__retting a .textBanner__stars {
    flex-wrap: nowrap;
  }
  .reg__retting .d-flex {
    row-gap: 25px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .tetx-white.f18.noBreakWrap.align-center.d-flex {
    width: 100%;
  }
  .reg__term {
    flex-wrap: wrap;
  }
  .reg__term .reg__payment--data {
    margin-top: 30px;
  }
  .reg__term-data {
    flex-wrap: wrap;
  }
  .reg__dmo--section .row {
    margin: 0;
  }
  .reg__dmo--section .row > .col-6 {
    width: 100%;
  }
  .reg__footer--form {
    margin-top: 30px;
  }
  .navbar-collapse {
    position: fixed;
    right: -280px;
    height: 100vh;
    width: 280px;
    transition: all 0.5s ease-in-out;
  }
  .navbar-collapse.show {
    transition: all 0.5s ease-in-out;
    right: 0;
  }
  .navbar-collapse.show.header-bg {
    background-color: rgb(67, 125, 192);
  }
  .navbar-collapse ul.navbar-nav {
    flex-direction: column !important;
    justify-content: flex-start;
  }
  .navbar-collapse ul.navbar-nav li {
    margin-bottom: 30px;
  }
  .reg__dvla--section .container:nth-child(even) .col-4 {
    order: 1;
  }

  .reg__dvla--section .container:nth-child(even) .col-8 {
    order: 2;
  }
  .reg__dvla--section .mb-70 {
    margin-bottom: 50px;
  }
  .reg__dvla--section .pr-45 {
    padding-right: 0;
  }
  .reg__image--posion {
    display: block;
    width: 64px;
    height: 64px;
  }
  .reg__data--image {
    position: absolute;
    left: 30px;
    top: 32px;
  }
  .reg__data--image {
    position: absolute;
    left: 30px;
    top: 32px;
  }

  .reg__data--text {
    margin: 0 !important;
  }

  .reg__data--text-title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .reg__data--text-title h3 {
    margin-bottom: 0;
    margin-left: 12px;
  }
}
@media (max-width: 767px) {
  .container {
    padding: 0 30px;
  }
  .dropdown .dropdown-menu.show {
    position: relative !important;
    transform: none !important;
    margin-top: 10px !important;
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
  }
  .dropdown .dropdown-menu a {
    font-weight: 300;
    /* color: #1a3057; */
  }
  .reg__hero--section .row .col-6 {
    width: 80%;
  }
  .reg__hero--section .reg__buy--button button + button {
    margin-left: 14px;
  }
  .reg__hero--section .reg__buy--button button.buy {
    min-width: 157px;
  }
  .reg__hero--section .reg__buy--button button.sell {
    min-width: 148px;
  }
  .reg__hero--section .reg__buy--button button img {
    margin-left: 10px;
  }
  .reg__dvla--section.pb-60 {
    padding-bottom: 140px;
  }
  .reg__whychoose--inner .col-6 {
    width: 100%;
  }
  /* .reg__whychoose--inner .reg__choose--data-box {
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
    padding: 30px;
  } */
  .reg__help--contect {
    flex-direction: column;
  }
  .reg__help--tm {
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
    align-items: start;
  }
  /* .reg__help--tm .reg__tel {
    justify-content: center;
  } */
  .reg__help--tm .reg__tel + .reg__tel {
    margin: 18px 0 0 0;
  }
  .reg__social--icon {
    justify-content: start;
  }
  .reg__help--social {
    text-align: center;
    margin: 18px 0 0 0;
  }
  .reg__help--social .reg__social--icon {
    flex-wrap: wrap;
    justify-content: center;
  }
  .reg__help--contect {
    padding: 45px 22px;
  }
  .reg__help--data {
    flex-wrap: wrap;
    row-gap: 30px;
  }
  .reg__help--data ul {
    width: 100%;
  }
  .reg__term {
    flex-wrap: wrap;
  }
  .reg__term .reg__payment--data {
    width: 100%;
    margin-top: 30px;
  }
  .reg__help--data ul + ul {
    padding-left: 0px;
  }
  .reg__data--text h5 {
    font-size: 20px;
    line-height: 28px;
  }
  .reg__help--contect .reg__tel .teg__tel--text {
    margin-left: 16px;
  }
  .car-image img {
    width: 100%;
  }
}
@media (max-width: 561px) {
  .reg__hero--section .row .col-6 {
    width: 100%;
  }
  .reg__whychoose--section {
    padding: 40px 0 15px;
  }
}
@media (max-width: 400px) {
  .container {
    padding: 0 15px;
  }
  .reg__buy--button {
    flex-wrap: wrap;
  }
  .reg__hero--section .reg__buy--button button.buy {
    min-width: 100%;
  }
  .reg__hero--section .reg__buy--button button.sell {
    min-width: 100%;
    margin: 10px 0 0;
  }
  .textBanner__stars {
    width: 100%;
  }
  .textBanner__stars svg {
    width: -moz-fit-content;
    width: fit-content;
  }
  .reg__payment--data img {
    width: 100%n;
  }
  .reg__choose--data-box .reg__data--image {
    height: 50px;
    width: 50px;
  }
  .reg__data--image img {
    width: 30px;
  }
  /* .reg__footer--form {
    flex-wrap: wrap;
    justify-content: center;
    background-image: none;
}
.reg__footer--form input {
  width: 100%;
}
.reg__search--button {
  margin-top: 15px;
} */
}
@media (min-width: 990px) and (max-width: 1199px) {
  h1,
  .f60 {
    font-size: 45px;
  }
}
.reg__faq--section {
  padding: 70px 0;
}
.reg__faq--section .accordion-button::after {
  background-image: url(../images/faqicon.svg);
}
.reg__faq--section .reg__addi--ques {
  color: #3b82f6;
  padding: 0 15px;
}
.reg__faq--section .reg__addi--ques:hover {
  color: #1f1f1f;
}

.reg__retting--image svg {
  width: 23px;
  height: 23px;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(45deg);
}

.accordion-button:not(.collapsed) {
  color: #1a3057;
  background-color: #b4cbe6;
}

@media (max-width: 880px) {
  .reg__faq--reting a.justify-center {
    justify-content: start;
  }
}
@media (max-width: 767px) {
  .reg__faq--section {
    padding: 10px 0 70px;
  }
}
.reg__sell--number {
  padding: 65px 0;
  color: #000000;
}
.reg__sell--number .form-check-input {
  border: 1px solid #232323;
}
/* .reg__sell--number .container {
  max-width: 1246px;
} */
.reg__sell--number .reg__sell--data {
  padding-right: 20px;
}
.reg__sell--number .reg__sell--data p {
  font-family: "Silka", "sans-serif";
}
.reg__sell--number .form__price .input-group-text {
  padding: 15px 20px;
  clip-path: path(
    "M845.1,12.1L831.9,1.4c-1.1-0.9-2.5-1.4-4-1.4H552.7H283.5H13.8C6.2,0,0,6.2,0,13.8V41c0,2.9,1.3,5.6,3.7,7.1 l6.1,5.2c1.5,1.9,3.8,2.8,6.1,2.8h272.7h273.8h276.6c4.6,0,8.3-3.7,8.3-8.3V17C847.5,15.1,846.6,13.3,845.1,12.1z"
  );
}
.reg__sell--number .fnumber .input-group-text {
  padding: 0px 10px;
}
/* .reg__sell--number .fnumber .green-image {
  margin: 2px 0px;
} */
.reg__sell--number .reg__list--data h5 {
  position: relative;
  width: fit-content;
}
.reg__sell--number .reg__list--data h5::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  min-width: 115px;
  width: 100%;
  height: 4px;
  flex-shrink: 0;
  border: 2px solid #00f5d0;
}
/* input{
  z-index: 0;
} */
.reg__sell--number .reg__list--data form input:not([type="checkbox"]) {
  background-color: transparent;
  z-index: 0;
}
.reg__sell--number .reg__list--data form .form_tearm a {
  color: #3b82f6;
}
.reg__sell--number .reg__list--data form .form_tearm a:hover {
  color: #1f1f1f;
}

@media (max-width: 1199px) {
  .reg__sell--number .reg__sell--data {
    padding-right: 30px;
  }
}
@media (max-width: 991px) {
  .reg__sell--number .reg__sell--data {
    padding-right: 0px;
  }
  .reg__list--data {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .p-40 {
    padding: 15px;
  }
}
.reg__cherished--inner-data {
  background-color: #f5f5f5;
  transition: none;
}
.reg__cherished--inner-data:hover {
  background-color: #e8e8e8;
  transition: none;
}

.reg__search--result {
  padding-bottom: 20px;
}
.reg__search--result .container {
  max-width: 1246px;
}
.reg__search--result .reg__cherished--inner {
  padding: 30px 0;
}
.reg__search--result .reg__cherished--inner div + div {
  margin-top: 10px;
}
.reg__search--result .cher__no--plate {
  padding: 12px 12px;
  width: 246px;
  border-radius: 2px;
}
.reg__search--result .reg__cher--number {
  background-color: #f5f5f5;
  /* margin: .8rem;
  padding: .5rem; */
}
.reg__search--result .reg__cher--number + .reg__cher--number {
  margin-top: 10px;
}
.reg__search--result .reg__cher--number ul.dropdown-menu {
  position: relative;
  border: none;
}
.reg__search--result .reg__cher--number .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
  border: none;
  padding: 10px 0;
}
.reg__search--result .reg__cher--number .number__btn {
  display: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 1px solid #1a3057;
  padding: 0;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.reg__search--result .reg__cher--number .number__btn::after {
  display: none !important;
  transition: all 0.5s ease-in-out;
}
.reg__search--result .reg__cher--number .number__btn svg {
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}
.reg__search--result .reg__cher--number .number__btn svg path {
  transition: all 0.5s ease-in-out;
  stroke: #1a3057;
}
.reg__search--result .reg__cher--number .number__btn.data-open {
  background-color: #1a3057;
  transition: all 0.5s ease-in-out;
}
.reg__search--result .reg__cher--number .number__btn.data-open svg {
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
}
.reg__search--result .reg__cher--number .number__btn.data-open svg path {
  transition: all 0.5s ease-in-out;
  stroke: #ffffff;
}
.reg__search--result .reg__cher--number .reg__buy--button .enquire-btn a,
.reg__search--result .reg__cher--number .reg__buy--button .offer-btn a,
.reg__search--result .reg__cher--number .reg__buy--button .buy-btn a {
  position: relative;
  padding: 18px 15px;
  width: 100%;
  min-width: 157px;
  line-height: 0.9 !important;
}
.reg__search--result .reg__cher--number .reg__buy--button .enquire-btn a svg,
.reg__search--result .reg__cher--number .reg__buy--button .offer-btn a svg,
.reg__search--result .reg__cher--number .reg__buy--button .buy-btn a svg {
  width: 45px;
}
.reg__search--result .dropdown-menu li + li {
  padding-left: 25px;
}
.reg__search--result .reg__cherished--inner-data .reg__cher--number,
.reg__search--result .reg__cherished--inner-data ul {
  background-color: transparent;
}

.buy-btn a:hover {
  color: #1f1f1f;
}

.offer-btn svg path {
  fill: #ffffff;
  /* stroke: #ffffff; */
}

@media (max-width: 991px) {
  .reg__cherished--inner-data {
    padding: 20px 0;
    margin-bottom: 15px;
    transition: all 0.5s ease-in-out;
  }
  .reg__cherished--inner-data.data-bg {
    background-color: #d9eaff;
    transition: all 0.5s ease-in-out;
  }
  .reg__search--result .reg__cher--number .number__btn {
    display: flex;
  }
  .reg__buy--button {
    display: none;
    width: 100%;
  }
  .reg__buy--button.show {
    width: 100%;
  }
  .buy-btn {
    padding-left: 0 !important;
    padding-right: 20px;
  }
  .enquire-btn {
    order: 2;
    padding-left: 0 !important;
    padding-right: 20px;
    background-color: transparent;
  }
  .offer-btn {
    background-color: transparent;
    order: 3;
    padding: 0 !important;
  }
  .reg__search--result .reg__cher--number ul {
    padding: 15px 0;
  }
  .reg__search--result .reg__cher--number ul {
    padding: 15px 0 0 0;
  }
}
@media (max-width: 767px) {
  .cher__no--plate h2 {
    font-size: 36px;
  }
  .reg__search--title .f36 {
    font-size: 24px;
  }
  /* .reg__cherished--data p {
    text-align: center;
  } */
  .reg__search--title .mt-60 {
    margin-top: 25px;
  }
  .reg__search--title .mb-60 {
    margin-bottom: 25px;
  }
  .reg__cherished .reg__cherished--data.mb-40 {
    margin-bottom: 32px;
  }
}
@media (max-width: 600px) {
  .reg__buy--button .enquire-btn {
    padding: 0;
    width: 50%;
  }
  .reg__buy--button .buy-btn {
    width: 50%;
  }
  .reg__buy--button .offer-btn {
    width: 100%;
    padding-top: 10px !important;
  }
  .reg__buy--button .enquire-btn a,
  .reg__buy--button .offer-btn a,
  .reg__buy--button .buy-btn a {
    min-width: 100% !important;
  }
  .reg__buy--button .buy-btn {
    padding-right: 6px;
  }
  .reg__search--result .dropdown-menu li + li {
    padding-left: 6px !important;
  }
}
@media (max-width: 429px) {
  .reg__search--result .cher__no--plate {
    padding: 6px 20px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
  .reg__search--result .cher__no--plate h2 {
    font-size: 32px;
  }
  .reg__buy--button .buy-btn,
  .reg__buy--button .enquire-btn {
    padding: 0 !important;
    width: 100%;
  }
  .reg__search--result .dropdown-menu li + li {
    padding-top: 8px !important;
    padding-left: 0px !important;
  }
}
.reg__checkout-main {
  color: #000000;
}

.reg__checkout--details {
  margin-top: 60px;
}

.reg__checkout--number {
  padding-right: 65px;
}
.reg__checkout--number .reg__plate--data {
  padding: 30px 15px;
  max-width: 512px;
  margin-inline: auto;
}

.reg__checkout--plat {
  padding: 24px 30px 30px 30px;
  border: 1px solid #c3c3c3;
  background: #f9f9f9;
}

.reg__plate--text {
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  background: #ecf1f7;
  padding: 15px;
}

.reg__checkout--button {
  position: relative;
  z-index: 9;
}
.reg__checkout--button a {
  padding: 17px 15px;
}
.reg__checkout--button a::before {
  border-image-slice: 32;
  border-width: 32px;
}
.reg__checkout--button a svg {
  margin-left: 0.7vw;
}

.reg__price--line {
  background: #efefef;
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.reg__price--greenline {
  background: #13f6c1;
  width: 100%;
  height: 4px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.reg__review--section.reg__checkout--review {
  background-color: #f9f9f9;
  margin-top: 0px;
  padding-top: 55px;
  padding-bottom: 50px;
}
.reg__review--section.reg__checkout--review .reg__review--data {
  padding: 0;
  margin: 0;
  box-shadow: none;
}
.reg__checkout--button .buy-btn a,
.reg__checkout--button .offer-btn a {
  line-height: 1.4;
}
.yellow__line-draw {
  position: relative;
}
.yellow__line-draw::before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 48px;
  height: 2px;
  background-color: #f6e05e;
}

.green--line {
  position: relative;
}
.green--line::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 48px;
  height: 4px;
  background-color: #13f6c1;
}

.reg__faq--section.reg__check--out-faq {
  padding: 60px 0;
}

.reg__checkout--inner p {
  font-family: "Silka", "sans-serif";
}
.reg__checkout--inner p:nth-child(3) {
  width: 100%;
  max-width: 490px;
  margin: 0 auto;
}

.reg__checkout-link svg {
  margin-left: 10px;
}
.reg__checkout-link svg path {
  transition: all 0.5s ease-in-out;
}
.reg__checkout-link a:hover svg path {
  stroke: #3b82f6;
  transition: all 0.5s ease-in-out;
}

.reg__checkout--manage p {
  max-width: 570px;
  width: 100%;
  margin: 0 auto 30px;
}

@media (max-width: 991px) {
  .reg__review--section.reg__checkout--review .reg__excelent {
    margin-bottom: 0;
  }
  .reg__review--section.reg__checkout--review .reg__review--data {
    padding: 40px 32px;
    border-radius: 12px;
    border: 1px solid #bedcf3;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  }
  .reg__checkout--plat {
    padding: 25px;
  }
  .reg__checkout--number {
    padding-right: 0px;
  }
  .reg__checkout--details {
    margin-top: 20px;
  }
  .reg__plate--data h1 {
    font-size: 60px;
  }
  .reg__plate--data .lh60 {
    line-height: 60px;
  }
  .reg__checkout-car {
    text-align: center;
    margin-top: 48px;
  }
  .reg__check--out-faq .faq__heading h2 {
    line-height: 54px;
  }
  .reg__check--out-faq .faq__heading h2::before {
    display: none;
  }
}
@media (max-width: 767px) {
  .reg__checkout--data .mb-64 {
    margin-bottom: 50px;
  }
  .reg__checkout--data .mtb-64 {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .offer-btn.mb-80 {
    margin-bottom: 50px;
  }
  .reg__checkout--review {
    margin-top: 0 !important;
  }
  .reg__checkout--review h5 {
    display: none;
  }
  .reg__checkout--number .reg__plate--data {
    margin-bottom: 8px;
  }
}
@media (max-width: 428px) {
  .fsubmit_btn button {
    font-size: 20px;
  }
  .fsubmit_btn button img {
    margin-left: 10px;
  }
  .reg__checkout--button a {
    font-size: 16px;
    justify-content: space-between;
  }
  .reg__checkout--button a::before {
    border-image-slice: 30;
    border-width: 30px;
  }
  .reg__checkout--plat {
    padding: 25px 10px;
  }
}
@media (max-width: 428px) {
  .reg__checkout--button a {
    font-size: 13px;
    justify-content: space-between;
  }
  .reg__checkout--button a::before {
    border-image-slice: 27.5;
    border-width: 27.5px;
  }
}
.swiper-wrapper {
  display: flex !important;
  flex-direction: row !important; /* or row-reverse, depending on your needs */
}
.error-container {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  color: var(--primary);
}

/* 404 page Page Start CSS */

#oopss {
  background-color: #bedcf3;
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
#oopss #error-text {
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Shabnam", Tahoma, sans-serif;
  color: #000;
  direction: rtl;
}
#oopss #error-text img {
  height: 250px;
  margin-top: -50px;
}
#oopss #error-text span {
  position: relative;
  font-size: 3.3em;
  font-weight: 900;
  margin-bottom: 0px;
  line-height: 1;
  margin-top: -50px;
}
#oopss #error-text p.p-a {
  font-size: 19px;
  /* margin: 30px 0 15px 0; */
}
#oopss #error-text p.p-b {
  font-size: 15px;
}
#oopss #error-text .back {
  color: #ffffff !important;
  font-size: 20px;
  border-radius: 10px;
  font-weight: 400;
  transition: all 300ms ease;
  text-transform: capitalize;
  background-color: #1a3057;
  border: 1px solid transparent;
  padding: 5px 30px;
  transition: all 0.5s ease-in-out;
}
#oopss #error-text .back:hover {
  background-color: transparent;
  border: 1px solid #1a3057;
  color: #1a3057 !important;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 768px) {
  #oopss #error-text span {
    font-size: 70px;
  }
}
/* 404 page Page End CSS */
.reg__whychoose--inner .row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.reg__whychoose--inner .col-12:nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}
.reg__whychoose--inner .col-12:nth-child(2) {
  grid-area: 2 / 1 / 4 / 2;
  height: fit-content;
}
.reg__whychoose--inner .col-12:nth-child(3) {
  grid-area: 1 / 2 / 4 / 3;
}
/* .reg__whychoose--inner .col-12 {
  break-inside: avoid !important;
  -webkit-break-inside: avoid !important;
} */
@media (max-width: 1366px) {
  .cher__no--plate h2 {
    font-size: 32px;
  }
}
/* 29-12-2023 */
.tr-management {
  background: #f9f9f9;
  padding: 50px 0;
}

@media (max-width: 576px) {
  .reg__review--section {
    margin-top: -40px;
  }
}

@media (max-width: 991px) {
  .reg__whychoose--inner .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .reg__whychoose--inner .col-12:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  .reg__whychoose--inner .col-12:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
    height: fit-content;
  }
  .reg__whychoose--inner .col-12:nth-child(3) {
    grid-area: 3 / 1 / 4 / 2;
  }
}
@media (min-width: 991px) {
  .reg__cherished--inner-data {
    margin-bottom: 10px;
    padding: 15px 0;
  }
}
/* 01-01-2024 Start CSS */
.reg__payment--data {
  display: flex;
  align-items: center;
}
.reg__payment--data .payment__image img {
  height: 32px;
}
.reg__payment--data .payment__image:not(:last-child) {
  margin-right: 8px;
}
/* @media(max-width: 1220px){
  .reg__whychoose--inner .row {
    display: block !important;
    columns: 12 450px;
    /* gap: 1rem; 
}
} */
.reg__search--button:active {
  background-color: transparent !important;
}
a.facebook.youtube img.in-svg {
  width: 28px;
  height: 28px;
}

@media (min-width: 430px) and (max-width: 500px) {
  .reg__search--result .cher__no--plate {
    width: 188px;
  }
}
/* 01-01-2024 End CSS */
footer button.reg__search--button:hover {
  background-color: transparent;
}
/* 03-01-2024 */
.reg__buypage input.form-control:focus,
.header input.form-control:focus {
  background-color: transparent !important;
  border-right-width: 2px !important;
  border-style: solid;
}

.mt-64 {
  margin-top: 64px !important;
}

.mtb-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}
.reg__checkout-link .in-svg {
  margin-left: 10px;
}
/*05-01-2024 Start css  */
input.form-control {
  color: #232323;
}
.car-inner p {
  width: 40%;
  margin-right: 15px;
}
.car-inner ul {
  width: 100%;
  list-style-type: disc;
  margin-left: 30px;
}
.car-inner p ul li a {
  font-size: 16px;
}
input.form-control::placeholder {
  font-family: "Silka";
  color: #8f8f8f;
}
@media (min-width: 991px){
  .car-inner.d-flex.fsi  p.pl-30.fsi{
    width: 60%;
  }
  
}
@media (min-width: 991px) and (max-width: 1270px) {
  input.form-control::placeholder {
    text-wrap: wrap;
    /* font-size: 14px; */
    vertical-align: middle;
  }
  .reg__hero--section .reg__car--title input {
    background-color: transparent;
    border: 0;
    /* color: #8F8F8F; */
    font-weight: 400;
    /* padding: 8px 10px 24px !important; */
  }
  /* .reg__hero--section .reg__car--title button {
    background-size: 110% 100%;
  } */
  .reg__hero--section .reg__car--title form {
    background-size: 900px;
  }
}
@media (min-width: 0px) and (max-width: 850px) {
  input.form-control::placeholder {
    text-wrap: wrap;
    /* font-size: 14px; */
    vertical-align: middle;
  }
  .reg__hero--section .reg__car--title input {
    background-color: transparent;
    border: 0;
    /* color: #8F8F8F; */
    font-weight: 400;
    /* padding: 7px 10px 24px !important; */
  }
  .reg__hero--section .reg__car--title button {
    background-size: cover;
  }
  .reg__hero--section .reg__car--title form {
    background-size: cover;
  }
}
@media (max-width: 991px) {
  .car-inner ul,
  .car-inner p {
    width: 100%;
    padding-right: 15px;
  }
}

@media (max-width: 600px) {
  .reg__hero--section {
    min-height: 695px;
    padding: 30px 0 100px;
  }
}
@media (max-width: 360px) {
  .reg__hero--section .reg__car--title input {
    padding: 7px 10px 32px !important;
  }
  /* .reg__hero--section .reg__car--title form {
    background-size: 705px;
  }
  .reg__hero--section .reg__car--title button {
    background-size: 155% 100%;
  } */
}
/*05-01-2024 End css  */

.reg__car--title form span {
  position: relative;
  display: block;
  width: 100%;
}

.reg__car--title form .custom-input-container::before {
  content: "Search names, initials, numbers, words or anything…";
  position: absolute;
  top: 50%;
  left: 15px;
  width: calc(100% - 15px);
  height: fit-content;
  text-align: start;
  font-family: "Silka";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  transform: translateY(-50%);
  color: #8f8f8f;
  z-index: 99999;
  pointer-events: none;
  padding-right: 10px;
}
@media (max-width: 500px) {
  .reg__car--title form .custom-input-container::before {
    font-size: 12px;
  }
}

/* loader css start */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}
.loader-container-with-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader css end */
.navbar-nav .dropdown-menu .dropdown-item,
.header .nav-link {
  font-family: Yukita Sans;
  font-size: 12px;
}
.reg__checkout-car svg {
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .reg__faq--section.reg__check--out-faq {
    padding-top: 30px;
  }
}
.reg__cherished div:first-child .reg__cherished--data {
  margin-top: 0;
}
@media (min-width: 991px) and (max-width: 1280px) {
  .offer-btn {
    flex-wrap: wrap;
  }
  .offer-btn a {
    margin: 0 0 20px 0;
  }
  .offer-btn a + a {
    margin: 0;
  }
}
@media (max-width: 768px) {
  .offer-btn {
    flex-wrap: wrap;
  }
  .offer-btn a {
    margin: 0 0 20px 0;
  }
  .offer-btn a + a {
    margin: 0;
  }
}
@media (max-width: 575px) {
  .reg__checkout--button a svg {
    margin-left: 10px;
  }
  .reg__checkout--button .call__checkout--button a + a svg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
}

/**plate-view--section**/
.gen-link__inner a:hover {
  text-decoration: underline;
}
.gen-link__inner span {
  cursor: not-allowed;
  color: #777;
}
.genrator__menu--image-inner .image-div img,
.genrator__menu--image-inner .image-div svg {
  max-width: 768px;
  /* width: 100%; */
  height: auto;
}
.width-full {
  width: 100% !important;
}
.genrator__menu--footer-inner {
  max-width: 768px;
  padding: 20px;
}
.genrator__menu--footer-inner .form label {
  margin-top: 10px;
  margin-bottom: 5px;
}
.genrator__menu--footer-inner .form .select {
  padding: 0 5px;
}
.genrator__menu--footer-inner .form input,
.select__inner {
  height: 40px;
  border-radius: 6px;
  border: solid 1px rgb(212, 212, 212);
  padding: 7px;
}
.select__inner {
  padding-right: 15px;
}
.select__inner select {
  border: 0;
}
.genrator__menu--footer-inner .reg__hero--section button {
  background-color: transparent;
}
.genrator__menu--footer-inner .reg__hero--section button:hover {
  color: #000;
}
.genrator__menu--footer-inner .reg__hero--section button:hover svg path {
  fill: unset;
  stroke: unset;
}
.genrator__menu--footer-inner .reg__hero--section button svg,
.genrator__menu--footer-inner .reg__hero--section button img {
  max-width: 20px;
}
.genrator__menu--footer-inner .reg__hero--section {
  background: unset;
  min-height: unset;
}
.genrator__menu--footer-inner .reg__hero--section::before {
  width: 0;
}
.plate-generator-text.porsche {
  width: 26.7%;
  height: 5.6%;
  left: 36.75%;
  top: 68.625%;
  opacity: 0.9;
  font-size: 2em;
}

@media (max-width: 991px) {
  .genrator__menu--image-inner .image-div img {
    max-width: 690px;
  }
  .genrator__menu--footer-inner {
    padding: 10px;
  }
}

.image-div {
  font-family: "UKNumberPlate", "sans-serif";
}
/*privacy-policy--start css*/
.privary-policy__section {
  margin-bottom: 64px;
}
.reg__updates--section {
  background: #1a3057;
  padding-top: 30px;
  padding-bottom: 28px;
}
.privary-policy__container .container {
  max-width: 952px;
}
.privary-policy__container h5 {
  line-height: 50.4px;
  font-size: 28px;
}
.privary-policy__container .container .privary-policy__container-top {
  margin-top: 60px;
}
.privary-policy__container .container > div {
  margin-top: 40px;
}
@media (max-width: 991px) {
  .privary-policy__section {
    margin-bottom: 40px;
  }
  .privary-policy__container .container .privary-policy__container-top {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .privary-policy__section {
    margin-bottom: 20px;
  }
  .privary-policy__container .container .privary-policy__container-top,
  .privary-policy__container .container > div {
    margin-top: 20px;
  }
  .privary-policy__section--inner .f18,
  .privary-policy__section--inner .f20 {
    font-size: 16px;
    line-height: 1.5;
  }
  .privary-policy__section--inner h5 {
    font-size: 20px;
    line-height: 1.5;
  }
}
/*privacy-policy--end css*/
/*reg__busniness-work--section css start**/
.reg__busniness-work--section {
  padding-top: 60px;
  padding-bottom: 79px;
}
.reg__busniness-work--top h5 {
  max-width: 798px;
  line-height: 50.4px;
}
.reg__busniness-work--imgcol {
  width: 50%;
  margin-left: 20px;
  max-width: 432px;
}
section.reg__articles--section {
  padding-bottom: 80px;
}
.reg__articles--section h5 {
  margin-bottom: 53px;
}
.reg__articals--inner {
  gap: 22px;
}
.reg__articals--inner > div {
  width: calc(100% / (3) - 15px);
}
.reg__articals--inner img {
  transition: all 0.3s ease-in-out;
}
.reg__articals--inner a:hover img {
  scale: 1.1;
}
.reg__articals--inner > div .text-inner {
  padding: 41px 52px 24px 40px;
  flex-grow: 1;
}
/*lg__subscribe-section*/
.lg__subscribe--section {
  background-image: url("../images/subs-sec-img.png");
  background-size: 100% 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-color: #004e6f;
}
.lg__subscribe-top h5 {
  max-width: 678px;
}
.lg__subscribe-top .form-inner {
  height: 72px;
  max-width: 512px;
}
.lg__subscribe-top form button {
  width: 46%;
  max-width: 212px;
  padding: 26px 21px;
  line-height: 20px;
}
.lg__subscribe-top form input {
  width: 64%;
}
.lg__subscribe-top .reg__footer--form .reg__search--button::before {
  border: 36px solid #13f6c1 !important;
  border-image: url(../images/footerbtbg.svg) 22 !important;
}
.lg__subscribe-top .reg__footer--form::before {
  border: 36px solid #ffffff !important;
  border-image: url(../images/whitebg.svg) 22 !important;
  width: 95%;
}
.lg__subscribe-top .reg__footer--form .form-control:focus {
  background-color: unset;
}
.lg__subscribe-top .reg__footer--form .reg__search--button {
  font-size: 18px;
  letter-spacing: 1.46px;
}
.lg__subscribe-top .reg__footer--form button img {
  width: clamp(40px, 4vw, 51px);
}
.lg__subscribe-top .reg__footer--form .email-input {
  padding: 17px 0 20px 29px;
}
.lg__subscribe-top .reg__footer--form .btn-outline-success:hover {
  background-color: unset;
}
/*lg__subscribe-section end css*/
/*news hero section css start*/
.hero-plate-col {
  padding: 49px 42px 79px 58px;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 67%,
    100% 75%,
    75% 100%,
    44% 100%,
    0 100%
  );
  background: #1a3057;
  max-width: 506px;
}
.hero-plate-col h1 {
  font-size: 44px;
  line-height: 47.52px;
}
.hero-plate-col h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -25px;
  width: 248px;
  height: 4px;
  background-color: #00f5d0;
}
/* .hero-plate-col p, .hero-links a, span{ */
.hero-plate-col p,
.hero-links a {
  max-width: 370px;
  line-height: 28.8px;
}
.hero-plate-col .hero-links {
  margin-top: 22px;
}
.lg-plate-hero-section {
  background-image: url("../images/news-herobg.png");
  background-size: cover;
  padding-bottom: 67px;
  background-position: center;
}
/*lg__news--section start css*/
.lg__news--section {
  padding-top: 72px;
  padding-bottom: 68px;
}
.lg__newshero--section.lg-plate-hero-section {
  padding-bottom: 73px;
}
.lg-plate-hero-section .hero-heading1 {
  margin-bottom: 70px;
}
.lg__errorhero--section .hero-heading1 {
  margin-bottom: 83px;
}
.btn-list-inner ul {
  gap: 11px;
}
.btn-list-inner ul.btn-list li {
  max-width: 209px;
  padding: 0;
}
.btn-list-inner ul.btn-list li a {
  z-index: 1;
  padding: 21px 23px 21px 22px;
  font-size: 16px;
}
.btn-list-inner ul.btn-list li a svg {
  transition: all 0.5s ease-in-out;
  max-width: clamp(48px, 2.5vw, 51px);
}
.btn-list-inner ul.btn-list li a::before {
  border: 36px solid #f9f9f9;
}
.btn-list-inner ul.btn-list li a:hover:before {
  border: 1px solid #f9f9f9;
}
.btn-list-inner ul.btn-list li a:hover {
  color: #fff;
}
.btn-list-inner ul.btn-list li a:hover svg path {
  fill: #fff;
}
.lg__hero-inner .lg-hero-btns {
  margin-top: 46px;
}
/*lg hero about css start*/
.lg__about-hero--section {
  height: 100%;
  max-height: 656px;
  background-image: url("../images/top_replace.jpg");
  padding-bottom: 250px;
  background-position: 100% 75%;
}
.lg__about-hero--section .hero-heading1 {
  margin-bottom: 45px !important;
}
.lg__about-hero--section .hero-heading1 span {
  font-size: 43px;
  padding-left: 23px;
}
.lg__about-hero--section h2::after {
  bottom: -19px;
  width: 246px;
}
.lg__about-hero--section .hero-plate-col {
  padding: 80px 78px 86.5px 58px;
  background-color: rgba(26, 48, 87, 0.88);
}
/*lg about page css end*/
/*lg__about-info--section start css*/
.heading-line::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -38px;
  width: 160px;
  height: 4px;
  background-color: #00344a;
}
section.lg__about-info--section {
  color: #091732;
}
.lg__about-info--section > .row > div {
  padding-top: 140px;
  padding-bottom: 140px;
}
.lg__about-info--section > .row > div:first-child {
  background-color: #f5ec33;
}
.lg__about-info--section > .row > div:last-child {
  background-color: #ecf2f4;
}
.lg__about-info--section .row .text-inner {
  margin-top: 74px;
  max-width: 532px;
}
.lg__about-info--section .right-col h5 {
  font-size: 21px;
  line-height: 29.4px;
}
.lg__about-info--section .right-col p {
  line-height: 25.6px;
  max-width: 219px;
  margin: 14px auto 0 auto !important;
}
.lg__about-info--section .right-col .row {
  padding: 0 30px;
  row-gap: 60px;
  max-width: 600px;
}
.lg__about-info--section .right-col .row > div {
  max-width: 240px;
}
/*lg__about-info--section end css*/
/*lg__about-help--section css start*/
.lg__about-help--section {
  background-image: url("../images/about_replace.jpg");
  background-size: cover;
  background-position: bottom;
  padding: 0 30px;
  padding-bottom: 100px;
}
.about-plate-col {
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 67%,
    100% 91%,
    86% 100%,
    44% 100%,
    0 100%
  );
  background-color: rgba(26, 48, 87, 0.88);
  padding: 144px 76px 220px;
}
.about-plate-col h2 {
  margin-bottom: 73px;
}
.about-plate-col h2::after {
  background: #f5ec33;
  bottom: -30px;
}
/*lg__about-help--section css end*/
.lg__about-help--inner .about-plate-col {
  max-width: 640px;
  width: fit-content;
}
.lg__about-help--inner .about-plate-col p {
  padding-right: 20px;
}
@media (min-width: 1440px) {
  /* .lg__subscribe-top > .row div:nth-child(2){
    justify-content: center !important;
  } */
  .lg__about-help--section h2 {
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  .lg__about-info--section h2 {
    font-size: 42px;
  }
}
@media (max-width: 1400px) {
  section.reg__articles--section {
    padding-bottom: 30px;
  }
  .lg__subscribe-top .reg__footer--form .email-input {
    padding: 15px 0 15px 15px;
  }
  .lg__subscribe-top .reg__footer--form .reg__search--button {
    font-size: 14px;
  }
  /* .lg-plate-hero-section > div{
    padding: 0 20px;
  } */
  .lg__subscribe--section .reg__footer--form input {
    font-size: 20px;
  }
  .lg__about-help--inner .about-plate-col p {
    font-size: 18px;
  }
  .lg__about-help--inner .about-plate-col {
    padding: 144px 73px 144px 56px;
    max-width: 570px;
  }
  .lg__about-help--inner .about-plate-col p {
    max-width: 426px;
  }
}
@media (max-width: 1200px) {
  .lg__about-info--section > .row > div {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .lg__about-info--section .right-col .row {
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .lg__subscribe--section {
    background-size: cover;
  }
}
@media (max-width: 991px) {
  .lg__news--section .three__img--cols {
    padding: 0 15px;
  }
  .mb-50 {
    margin-bottom: 30px;
  }
  .reg__busniness-work--section {
    padding: 30px 0 10px 0;
  }
  .reg__articles--section h5 {
    margin-bottom: 30px;
  }
  .lg__subscribe-top h5 {
    max-width: 100%;
  }
  .lg__subscribe-top .reg__footer--form::before {
    border: 30px solid #ffffff !important;
    border-image: url(../images/whitebg.svg) 22 !important;
  }
  .lg__subscribe-top .reg__footer--form .reg__search--button::before {
    border: 30px solid #13f6c1 !important;
    border-image: url(../images/footerbtbg.svg) 22 !important;
  }
  .lg__subscribe-top .form-inner {
    height: 60px;
  }
  .lg-plate-hero-section .hero-heading1 {
    margin-bottom: 50px !important;
  }
  .reg__busniness-work--top h5 {
    max-width: 100%;
    line-height: 1.5;
  }
  .reg__articals--inner > div {
    width: calc(100% / (2) - 12px);
  }
  .lg__about-hero--section .hero-plate-col {
    padding: 40px;
  }
  .lg__about-info--section > .row > div {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .lg__about-info--section .f20 {
    font-size: 16px;
    line-height: 1.5;
  }
  .heading-line::after {
    bottom: -25px;
  }
  .lg__about-info--section > .row > div img {
    max-width: 120px;
  }
  .lg__about-help--inner .about-plate-col {
    padding: 30px;
  }
  .lg__about-help--inner .about-plate-col p {
    font-size: 16px;
    line-height: 1.5;
  }
  .about-plate-col h2 {
    margin-bottom: 50px;
  }
  .about-plate-col h2::after {
    bottom: -20px;
  }
}
@media (max-width: 767px) {
  .reg__busniness-work--imgcol {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px !important;
  }
  .reg__busniness-work--text-col p {
    margin-top: 16px !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
  }
  .lg__subscribe-top form {
    width: 100%;
  }
  .lg__hero-inner .lg-hero-btns {
    margin-top: 30px;
  }
  .lg__hero-inner h1 {
    font-size: 32px;
  }
  .hero-plate-col {
    padding: 34px;
  }
  .hero-plate-col > p,
  a,
  span {
    font-size: 16px;
  }
  .lg-plate-hero-section {
    padding: 0 0 150px 0 !important;
  }
  .lg-plate-hero-section {
    background-position: right;
  }
  .hero-plate-col .hero-links {
    margin-top: 16px;
  }
  .reg__articals--inner > div {
    width: 100%;
  }
  .reg__articals--inner > div .text-inner {
    padding: 41px 52px 40px 40px;
  }
  .lg__about-hero--section div.hero-plate-col {
    padding: 30px 25px;
    max-width: 368px;
  }
  .lg__about-info--section .right-col .row > div {
    max-width: 50%;
    padding: 0 20px !important;
    gap: 30px;
  }
  .lg__about-info--section > .row > div {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .lg__about-info--section .row div.text-inner {
    margin-top: 50px;
  }
}
@media (max-width: 480px) {
  .lg__about-info--section .right-col .row > div {
    max-width: 100%;
  }
  div.btn-list-inner ul.btn-list li {
    max-width: 100% !important;
  }
}

/*reg__busniness-work--section css end**/
.lg__subscribe-top .reg__footer--form button img {
  margin-left: 15px;
}
.btn-list-inner ul.btn-list li a:hover {
  color: #fff !important;
}

.reg__busniness-work--inner .reg__busniness-work--top p:first-child {
  margin-bottom: 0;
  line-height: 2;
}
.lg__errorhero--section li img {
  width: 80px !important;
}
.reg__busniness-work--inner .reg__busniness-work--top p:last-child {
  margin-bottom: 5px !important;
  line-height: 1.8 !important;
}
.lg__subscribe--section .reg__footer--form {
  /* background-image: url(http://localhost:3000/static/media/footerwhitebg.af21808a5cff8884b66c.svg); */
  background-repeat: no-repeat;
  background-position: left;
  background-size: 100% 101%;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
@media (max-width: 991px) {
  .lg__about-info--section .row div.text-inner {
    max-width: 100%;
  }
  .lg__about-info--section .right-col .row > div {
    max-width: 300px;
  }
  .lg__about-info--section .right-col .row {
    max-width: 100%;
    justify-content: center !important;
  }
  .lg__subscribe--section .reg__footer--form {
    background-size: 83% 101%;
  }
  .reg__busniness-work--top p {
    text-align: end;
  }
}
@media (max-width: 768px) {
  .lg-plate-hero-section {
    /* background-size: 100% 100%; */
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }
  .lg__newshero--section.lg-plate-hero-section {
    background-position: top center;
  }

  div.btn-list-inner ul.btn-list li {
    max-width: 48%;
  }
  .lg__about-info--section .right-col h5 {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .lg__about-info--section .right-col .row {
    justify-content: center !important;
  }
}
@media (max-width: 568px) {
  .lg__subscribe--section .reg__footer--form input {
    font-size: 16px;
  }
  .lg__subscribe--section .reg__footer--form {
    background-size: cover;
  }
  .lg__subscribe-top .reg__footer--form button img {
    width: clamp(32px, 4vw, 51px);
    margin-left: 5px;
    margin-right: 0;
  }

  .lg__subscribe-top .reg__footer--form .reg__search--button {
    font-size: 10px;
    position: relative;
    left: 3px;
  }
  .reg__review--section .reg__review--data {
    padding: 50px 0px 10px;
  }
}

/*   
@media(max-width: 500px){
  .lg__subscribe--section .reg__footer--form {
    background-size: 93% 101%;
}
} */
/*18-03-2024 Start css  */
.lg__newshero--section .hero-plate-col h1::after {
  width: 160px;
}
.accordion-item .accordion-body {
  padding-left: 0;
}
@media (min-width: 1440px) {
  .lg__about-info--section .lg__about-inner {
    padding-right: 145px !important;
  }
}
@media (min-width: 991px) {
  header ul {
    max-width: 1400px;
    margin: 0 auto;
    padding-inline: 30px !important;
  }
}
@media (min-width: 768px) {
  .reg__help--social {
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .lg__about-info--section .lg__about-inner {
    padding-inline: 30px !important;
  }
  .car-inner ul.pl-30 {
    padding-left: 0;
  }
  .header .dropdown .nav-link:after {
    background-image: url(https://i.ibb.co/Q9wDgcN/Vector.png);
    background-repeat: no-repeat;
    background-position-y: 1;
    top: 4px;
    position: absolute;
    width: 10px;
    height: 6px;
  }
}
@media (max-width: 746px) {
  .reg__term-data .right-line:first-child::before {
    display: none;
  }
}
@media (max-width: 768px) {
  .lg__about-info--section .lg__about-inner {
    padding-inline: 30px !important;
  }
  .hero-plate-col h1::after {
    width: 175px;
  }
}
@media (max-width: 400px) {
  .accordion-item .accordion-body a {
    font-size: 14px;
  }
}
/*18-03-2024 End css  */
/* 22-03-2024 Start CSS */
.reg__blog--images {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}

.reg__blog--images img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 600px) and (max-width: 768px) {
  .reg__blog--images {
    position: relative;
    padding-top: 70%;
    overflow: hidden;
  }
}
/* 22-03-2024 End CSS */
/* 26-03-2024 Start CSS */
.form-check input {
  margin-top: 7px;
}
.reg__enquiry--data {
  background-image: url(../images/callheadingbg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  /* background-color: #1a3057; */
}
.reg__enquiry--data a {
  text-wrap: nowrap;
}
.reg__section--heading {
  position: relative;
  border-bottom: 4px solid #00f5d0;
  padding-bottom: 15px;
}
.enquiry__form input,
.enquiry__form textarea {
  /* background: transparent; */
  z-index: 0 !important;
}
.enquiry__form textarea {
  resize: none;
}
.enquiry__form span {
  line-height: 0;
}
.form-check-input:focus {
  border: 1px solid #000 !important;
}
.enquiry__form .form-control:focus {
  /* box-shadow: none;
  background-color: transparent; */
}
.reg__iimportant {
  color: #c92d2d;
  /* padding-left: 5px; */
  line-height: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.reg__enquiry__form .reg__list--data {
  max-width: 734px;
}
.separator {
  width: 100%;
  height: 4px;
  background-color: #c3c3c3;
  margin-bottom: 22px;
}
.reg__enquiry__form .reg__car--details {
  max-width: 400px;
  /* padding: 45px 21px 50px 21px; */
  padding: 0px 21px 50px 21px;
  margin: 0 auto;
}
.reg__payment--image img {
  width: auto;
  height: 26px;
}
.reg__payment--image img + img {
  margin-left: 20px;
}
/* .reg__car--details svg, */
.reg__car--details img.mb-23 {
  width: 100%;
  max-width: 402px;
  height: auto;
}
@media (max-width: 1280px) {
  .reg__enquiry--data .f36 {
    font-size: 30px;
  }
}
@media (min-width: 991px) and (max-width: 1200px) {
  .reg__list--data form label.f16 {
    font-size: 13px;
  }
}
@media (min-width: 430px) and (max-width: 768px) {
  .enquiry__form .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .enquiry__form .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}
@media (max-width: 991px) {
  .reg__enquiry--data .f36 {
    font-size: 18px;
  }
  .reg__enquiry__form .reg__list--data {
    margin: 0 auto;
  }
  .reg__section--heading h1.f36 {
    font-size: 24px;
  }
  .reg__list--data {
    padding: 25px;
  }
  .reg__car--details {
    padding: 22px 0px 22px 0px;
  }
  .genrator__menu--section .row.justify-between {
    justify-content: center;
  }
}
@media (max-width: 400px) {
  .reg__list--data {
    padding-inline: 15px;
  }
  button.reg__sell--submit {
    font-size: 18px;
  }
}
.reg__help--form,
.reg__list--data {
  max-width: 734px;
  /* margin-left: auto; */
}
.genrator__menu--inner.col-lg-5 {
  max-width: 518px;
  margin-top: 47px;
}
/* 26-03-2024 End CSS */
/* 27-03-2024 Strat CSS */
/* ============ Offer page css ============= */
.row.enquiry__form {
  margin: 0 -7px;
}

.row.enquiry__form .col-12 {
  padding: 0 7px;
}
.blue-text-hover:hover {
  color: #000000;
}
.offer__data .genrator__menu--inner.col-lg-5 {
  max-width: 610px;
  margin-top: 0px;
  width: 45%;
}
.offer__data .reg__help--form {
  max-width: 710px;
}
.offer__data .enquiry__form ul {
  padding-left: 30px;
}
/* .offer__data .green-image {
  padding: 15px 20px;
} */
.reg__makeoffer h1,
.reg__makeoffer h5 {
  position: relative;
}
.reg__makeoffer h1::before,
.reg__makeoffer h5::before {
  background-color: #00f5d0;
  bottom: -10px;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  width: 44%;
  max-width: 125px;
}

.reg__offer--pricelist {
  max-width: 331px;
  margin-left: auto;
}
.reg__offer--pricelist p {
  font-size: 16px;
}
@media (max-width: 768px) {
  .reg__offer--pricelist p {
    font-size: 13px;
  }
}
.reg__offer--pricelist .reg__price--line {
  margin-top: 10px;
  margin-bottom: 10px;
}
.reg__pricelist--heading {
  background-color: #f3f3f3;
  padding: 10px;
  border: 1px solid #eaeaea;
}

.reg__offer--pricelist .price__list {
  padding: 10px;
  border: 1px solid #eaeaea;
  border-top: 0;
}
.reg__car--details .reg__price--line {
  background-color: #c3c3c3;
}
.reg__map--data {
  position: relative;
  padding-top: 90%;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
}

.reg__map--data img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reg__number--data .reg__number--details img {
  width: 17px;
  height: 17px;
}
.reg__number--data .reg__mail--details img {
  width: 19px;
  height: 19px;
}
@media (min-width: 991px) and (max-width: 1280px) {
  .makeoffer__row .col-5 {
    width: 100%;
  }
  .reg__offer--pricelist {
    margin-left: 0;
  }
}
@media (min-width: 991px) and (max-width: 1350px) {
  .offer__data .reg__help--form {
    width: 60%;
  }
  .offer__data .genrator__menu--inner.col-lg-5 {
    max-width: 100%;
    margin-top: 0px;
    width: 40%;
  }
  .genrator__menu--image-inner .image-div {
    padding-left: 0;
  }
  .offer__data .reg__help--form.reg__contact--form {
    width: 58%;
  }
}
@media (max-width: 991px) {
  .offer__data .genrator__menu--image-inner .image-div {
    padding: 0;
  }
  .offer__data .reg__help--form {
    width: 100%;
    max-width: 100%;
  }
  .offer__data .genrator__menu--inner.col-lg-5 {
    /* max-width: 100%; */
    margin-top: 0px;
    width: 100%;
  }
  .offer__data .reg__list--data {
    margin-inline: auto;
  }
  .offer__data .genrator__menu--image-inner .image-div img {
    max-width: 450px;
  }
  .makeoffer__row .col-7 {
    flex: 0 0 auto;
  }
  .reg__makeoffer h5 {
    margin-bottom: 15px;
  }
  .reg__contact--inner {
    max-width: 750px;
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .makeoffer__row .col-5,
  .makeoffer__row .col-7 {
    flex: 0 0 100%;
  }
  .makeoffer__row .reg__offer--pricelist {
    margin-left: 0;
  }
}
/* 27-03-2024 End CSS */
/* 28-03-2024 Start CSS */
@media (max-width: 768px) {
  .reg__hero--section .reg__buy--button button {
    margin: 0 auto;
  }
  .genrator__menu--center {
    margin-bottom: 30px;
  }
}
/* Header css Start */
.navbar-collapse .mobile_footer {
  display: none;
}
@media (max-width: 991px) {
  .navbar-collapse {
    top: 0;
    overflow: auto;
    overflow-y: scroll;
    padding: 20px;
  }
  .header-bg {
    background-color: #222222;
  }
  .navbar-collapse.show.header-bg {
    background-color: #222222;
    width: 100%;
    top: 0;
    padding: 20px;
  }
  .mobile_logo .btn-close {
    filter: invert(1);
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
  .navbar-collapse ul.navbar-nav {
    height: calc(100% - 200px);
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
  }
  .navbar-collapse .mobile_footer {
    max-width: 450px;
    margin-top: 30px;
    display: flex;
    height: 100%;
    align-items: end;
    margin-bottom: -25px;
    margin-left: auto;
    margin-right: auto;
  }
  .mobile_logo {
    border-bottom: 1px solid #fff;
    padding-bottom: 15px;
  }
}
.reg__search--result .reg__search--title h1 {
  margin: 20px 0px;
}

.reg__cherished--data p {
  font-family: "Yukita Sans";
  font-weight: 300;
}

.reg__search--result .cher__no--plate {
  border-radius: 6.5px;
  padding: 0px;
  padding-top: 56px;
  width: 246px;
  position: relative;
  aspect-ratio: 13 / 3;
}

.reg__search--result .cher__no--plate h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 46px;
}

.reg__cherished
  .reg__cher--number.d-flex.flex-wrap.align-center.justify-between {
  gap: 5px;
}

@media (max-width: 576px) {
  .reg__search--result .cher__no--plate {
    width: 160px;
  }
  .reg__search--result .cher__no--plate h2 {
    font-size: 30px;
  }
  .reg__search--result .reg__search--title h1 {
    margin: 30px 0px;
  }
}
/* Header css */
/* New Check our page */
.plate__type {
  padding-inline: 20px;
  background-color: #e9eff8;
}

.plate__type ul {
  list-style: disc;
  padding-left: 20px;
  margin: 15px 0 30px;
}

.reg__enquiry__form .cher__no--plate {
  position: relative;
  padding-top: 74px;
  background-image: url(../images/platebg.png);
  background-repeat: no-repeat;
  background-position: 50% 95%;
  border-radius: 5px;
}

.reg__enquiry__form .cher__no--plate h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(43px, 2.78vw, 45px);
}

.plate__inner.row {
  margin: 0 -5px;
}

.plate__inner.row > * {
  padding: 0 5px;
}

/* .reg__enquiry__form h5 {
  font-size: 20px;
} */

.row.enquiry__form > .col-6 {
  padding: 0 7px;
}

.plate__type .form-check-input:checked {
  background-color: #ffffff;
  border-color: #000000;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%230d6efd'/%3e%3c/svg%3e") !important;
}

.plate__type .form-check-input {
  border-color: #000000;
}

.form-control.card {
  padding-right: 50px;
  /* background: url(../assets/images/card.png) no-repeat calc(100% - 12px) center; */
}
.reg__checkout--section .reg__section--heading {
  position: relative;
  border-bottom: 4px solid #00f5d0;
  padding-bottom: 15px;
}

@media (max-width: 991px) {
  .reg__list--data {
    padding: 24px;
  }

  .reg__list--data .mb-35 {
    margin-bottom: 12px;
  }
  .plate__type ul {
    margin: 15px 0;
  }
  .row.card__details {
    margin: 0 -5px;
  }

  .row.card__details > * {
    padding: 0 5px;
  }
}
@media (max-width: 418px) {
  .card__details .col-8,
  .card__details .col-4 {
    width: 100%;
  }
  .reg__enquiry__form .cher__no--plate h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(30px, 2.78vw, 45px);
  }
}
p.copyrightdata {
  font-size: 12px;
  color: #c1c1c1;
  max-width: 650px;
  margin-inline: auto;
}
.reg__checkout--inner .enquiry__form p {
  max-width: 100%;
}
/* New Check our page */
/* 28-03-2024 End CSS */
/*plate order css start*/
.reg__plateorder--section {
  /* padding-left: 66px;
  padding-right: 66px; */
  padding-top: 38px;
  padding-bottom: 152px;
}
#plateorderaccordion {
  margin-top: 42px;
  margin-bottom: 35px;
}
#plateorderaccordion .accordion-body {
  padding: 27px 59px 44px 40px;
  background-color: #e9eff8;
}
.reg__newmakeoffer h5::before,
#plateorderaccordion .reg__makeoffer h5::before {
  bottom: -13px;
  max-width: 128px;
}
.reg__newmakeoffer h5::before {
  bottom: -21px;
}
#collapseOne .accordion__wrapper .accordion__list {
  list-style-type: disc;
}
/*order plate select css start*/
.orderplate_card_area {
  max-width: 317px;
  margin-top: 10px;
}
.order_plate_card {
  border: 1px solid #efefef;
  transition: all 0.3s ease-in;
}
.order_plate_title {
  padding: 26px 12px;
  background-color: #dadada;
  transition: all 0.3s ease-in;
  color: #00344a;
}
.order_plate_image {
  transition: all 0.3s ease-in-out;
}
#collapseThree .orderplate_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}
.order_plate_image img {
  transition: all 0.3s ease-in-out;
}
.order_plate_image .img2 {
  visibility: hidden;
}
#collapseThree
  .orderplate_checkbox:checked
  ~ .order_plate_card
  .order_plate_title {
  background-color: #1a3057;
  color: #fff;
}

#collapseThree
  .orderplate_checkbox:hover
  ~ .order_plate_card
  .order_plate_image {
  padding-top: 91%;
}
.reg__section--bottom {
  padding: 24px 36px 27px 20px;
  background-color: #e9eff8;
}
/*right col*/
.plateorder-billing-wrapper {
  margin-top: 49px;
}
.plateOrder {
  position: relative;
}
.plateOrder h2 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.plateOrder img {
  max-width: 314px;
}
.plateorder__top {
  margin-bottom: 28px;
}
.reg__plateorder--button .reg__checkout--button a {
  padding: 16.5px 15px;
}
.reg__plateorder--button .reg__checkout--button a::before {
  border-image-slice: 32;
  border-width: 32px;
}
.reg__plateorder--button .offer-btn a:hover::before {
  border: 1px solid #1a3057;
  transition: all 0.5s ease-in-out;
}
#plateorderaccordion .accordion-button:not(.collapsed) {
  color: #1a3057;
  background-color: #e9eff8;
  box-shadow: unset;
}
#plateorderaccordion .accordion-button {
  background-color: #e9eff8;
}
#plateorderaccordion .accordion-button::after {
  display: none;
}
.order_plate_image {
  padding-top: 61%;
}
.order_plate_image img {
  object-fit: cover;
}
.reg__plateorder--right .reg__price--line {
  margin-top: 25px;
  margin-bottom: 25px;
}
.reg__checkout--inner .reg__section--heading {
  max-width: 734px;
}
@media (min-width: 992px) {
  #plateorderaccordion .accordion-item .accordion-button {
    display: none;
  }
  #plateorderaccordion .accordion-item .accordion-collapse {
    display: block !important;
  }
  .plateorderleft__inner {
    max-width: 814px;
  }
  .plateorderright__inner {
    max-width: 406px;
  }
  #collapseOne .accordion__wrapper {
    margin-top: 45px;
  }
  #collapseTwo .accordion__wrapper {
    margin-top: 41px;
  }
  #collapseThree .accordion__wrapper {
    margin-top: 23px;
  }
  .accordion-item {
    margin-top: 30px;
  }
  #collapseOne .accordion__wrapper .form {
    max-width: 315px;
  }
  #collapseOne .accordion__wrapper .accordion__list {
    max-width: 307px;
    list-style-type: disc;
  }
  .accordion__list li:nth-child(3) {
    max-width: 260px;
  }
}
@media (max-width: 1400px) {
  .reg__plateorder--section {
    padding: 30px;
  }
  .reg__plateorder--button .reg__checkout--button a {
    font-size: 16px;
    padding: 16px 15px;
  }
  .reg__plateorder--button .reg__checkout--button a::before {
    border-image-slice: 28;
    border-width: 28px;
  }
}
@media (max-width: 1280px) {
  #plateorderaccordion .accordion-body {
    padding: 30px;
  }
  .plateOrder h2.f60 {
    font-size: 60px;
  }
}
@media (max-width: 991px) {
  .accordion__heading {
    display: none;
  }
  #plateorderaccordion .accordion-body {
    padding: 0 30px 30px 30px;
  }
  .plateorder-billing-wrapper {
    border: 1px solid #c3c3c3;
    background: #f9f9f9;
    padding: 25px;
  }
  .reg__plateorder--button .reg__checkout--button a::before {
    border-width: 34px;
  }
  .reg__plateorder--button .reg__checkout--button a {
    padding: 18px 15px;
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .reg__plateorder--section {
    padding: 16px;
  }
}
@media (max-width: 430px) {
  .reg__plateorder--button .reg__checkout--button a {
    font-size: 12px;
    padding: 16px 15px;
  }
  .reg__plateorder--button .reg__checkout--button a::before {
    border-width: 25px;
  }
  .reg__payment--image img {
    height: auto;
    width: 28%;
    object-fit: contain;
  }
  .plateorder-billing-wrapper {
    padding: 15px;
  }
  .plateOrder h2.f60 {
    font-size: 50px;
  }
  .reg__plateorder--button .reg__checkout--button a svg {
    margin-left: 18px;
    width: 30px;
  }
}
/* 29-03-2024 Start CSS */
.reg__border::after {
  content: "";
  background-color: #00f5d0;
  bottom: -21px;
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}
.reg__number--data a {
  line-height: 1.8;
}

.d-flex.align-center.mb-20 p {
  line-height: 1.8;
}

.genrator__menu--image-inner img {
  margin-bottom: 10px;
}
.offer__data .enquiry__form ul, .plate-generator-box ul {
  padding-left: 22px;
}
.genrator__menu--section {
  color: #111111;
}
/* .reg__list--data .form-check{
   align-items: center;
   display: flex;
} */

.reg__list--data .form-check-input {
  flex-shrink: 0;
}

.reg__help--social span {
  max-width: 100%;
}

/* .reg__list--data .form-check{
   align-items: center;
   display: flex;
} */
.enquiry__form .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E") !important;
}
.reg__plateorder--section input[type="radio"] {
  height: 18px;
  width: 18px;
}
#collapseThree .row {
  justify-content: center;
  row-gap: 20px;
}
#collapseThree .row .ref__font--data {
  max-width: 351px;
  padding: 0;
  padding-right: 10px;
}
@media (max-width: 991px) {
  .reg__plateorder--section {
    padding-bottom: 38px;
  }
}
.reg__valuation--data label {
  font-size: 16px !important;
}
.reg__valuation--data .reg__list--data .form-check {
  align-items: self-start;
}
input[type="checkbox"] {
  margin-top: 5px;
  margin-right: 15px;
}
input[type="radio"] {
  margin-right: 5px;
}
.reg__valuation--data .reg__list--data h5 {
  width: fit-content;
}
.reg__valuation--data .reg__list--data h5::before {
  width: 100%;
}
.reg__valuation--data .reg__sell--data {
  padding-right: 0;
}

.reg__valuation--data .col-lg-6 {
  width: 43.5%;
}

.reg__valuation--data .col-lg-6 + .col-lg-6 {
  width: 51.76%;
  margin-left: auto;
}
@media (min-width: 1100px) and (max-width: 1280px) {
  .reg__valuation--data .reg__list--data .reg__middle--name {
    width: 50%;
  }
}
@media (max-width: 1100px) {
  .reg__valuation--data .col-lg-6,
  .reg__valuation--data .col-lg-6 + .col-lg-6 {
    width: 100%;
  }
  .reg__valuation--data .reg__list--data {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .reg__valuation--heading h1 {
    font-size: 25px !important;
    line-height: 35px;
    flex: auto !important;
  }

  .reg__valuation--heading .fsubmit_btn {
    margin-left: 0 !important;
    margin-top: 15px;
  }

  .reg__valuation--heading .fsubmit_btn button {
    font-size: 16px;
    padding: 25px !important;
  }
}
@media (max-width: 575px) {
  .reg__valuation--data button .ml-25 {
    margin-left: 8px;
  }
  .reg__valuation--data button {
    font-size: 18px;
  }
}
@media (max-width: 420px) {
  .reg__valuation--data .fsubmit_btn button::before {
    border: 40.5px solid #13f6c1;
    -o-border-image: url(../images/fsubbtn.svg) 40.5;
    border-image: url(../images/fsubbtn.svg) 40.5;
  }
  .reg__enquiry--data {
    background-size: 100% 100%;
  }
}
@media (max-width: 400px) {
  .reg__postcode--fild,
  .reg__country--fild,
  .reg__county--fild,
  .reg__town--fild {
    width: 100%;
  }
}
.reg__list--data .form-check-input {
  margin-top: 5px;
  border-color: #000000;
}
.reg__valuation--heading .reg__sell--submit:hover {
  color: #ffffff;
}

.reg__valuation--heading .reg__sell--submit:hover svg path {
  fill: #ffffff;
}
.reg__enquiry--data h1 {
  font-size: 32px;
}
.reg__valuation--heading .reg__enquiry--data h1,
.reg__valuation--heading .reg__enquiry--data h2 {
  flex: 1;
}
.reg__valuation--heading .reg__enquiry--data .fsubmit_btn button {
  padding: 25px 72px;
}
.reg__valuation--heading .reg__enquiry--data .fsubmit_btn {
  max-width: 408px;
  margin-left: 37px;
}
.reg__valuation--heading .reg__enquiry--data {
  padding-block: 25px;
}
.offer__data {
  margin-top: 50px;
}
.reg__data--check-label .form-check-input {
  margin-top: 5px;
  /* border-radius: 100%; */
  border-color: #000000;
}
.reg__valuation--heading .reg__sell--submit:hover {
  color: #ffffff;
} 

.reg__valuation--heading .reg__sell--submit:hover svg path {
  fill: #ffffff;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;
}
.bgwhite-image {
  position: relative;
}
.bgwhite-image::before {
  content: "";
  position: absolute;
  border: 28.5px solid red;
  -o-border-image: url(../images/fbgwhitedesign.svg) 25.5;
  border-image: url(../images/fbgwhitedesign.svg) 25.5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
section.reg__thayou--section {
  padding: 80px 0;
}
.reg__thayou--section .fsubmit_btn a {
  line-height: 0.9 !important;
  padding: 20px 15px;
  position: relative;
  width: 100%;
  max-width: 401px !important;
  margin-inline: auto;
}
.reg__thayou--section .fsubmit_btn a::before {
  content: "";
  position: absolute;
  border: 32px solid #13f6c1;
  -o-border-image: url(../images/fsubbtn.svg) 32;
  border-image: url(../images/fsubbtn.svg) 32;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}
.reg__thayou--section .fsubmit_btn a img,
.reg__thayou--section .fsubmit_btn a svg {
  rotate: 180deg;
  margin-right: 25px;
  margin-left: 0px;
}
.reg__thayou--section .fsubmit_btn a:hover::before {
  border: 1px solid #13f6c1;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 991px) {
  .reg__thayou--section h1 {
    font-size: 30px;
  }
  .reg__thayou--section h4 {
    font-size: 22px;
  }
  .reg__enquiry__form .reg__car--details {
    padding: 20px 21px 50px;
  }
  .reg__car--details img.mb-23,
  .reg__car--details svg {
    margin-bottom: 25px;
  }
  .reg__checkout--inner .reg__section--heading {
    max-width: 100%;
  }
}
/* Order confirmation css */
.reg__orderconfirmation--section {
  padding: 78px 38px 77px 47px;
}
.reg__orderconfirmation--section .reg__plate--data .plate-img {
  max-width: 382px;
}
.checkbox {
  height: 18px;
  width: 18px;
}
.description__head {
  background-color: #1a3057;
  border-radius: 10px 10px 0 0;
}
.description__center {
  padding: 30px 15px;
}
.orderconfirm__description {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
.description__bottom {
  background-color: #b4cbe6;
  border-radius: 0 0 10px 10px;
}
.border-b-dashed {
  border-bottom: 1px dashed #c7c7c7;
}
@media (max-width: 1199px) {
  .reg__orderconfirmation--section .f24 {
    font-size: 20px;
  }
  .reg__orderconfirmation--section .f20 {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .reg__orderconfirmation--section {
    padding: 25px 0;
  }
}
@media (max-width: 1024px) {
  .reg__plate--center,
  .reg__plate--head {
    gap: 10px;
  }
  .reg__plate--head {
    margin-bottom: 10px;
  }
  .reg__plate--center {
    margin-bottom: 10px !important;
  }
}
@media (max-width: 767px) {
  .reg__data--section .orderconfirm__left {
    order: 2;
  }
}
@media (max-width: 480px) {
  .reg__orderconfirmation--section .reg__plate--data h1 {
    font-size: 42px;
  }
  .reg__orderconfirmation--section .f36 {
    font-size: 28px;
  }
}
.reg__hero--section .reg__buy--button.download__button button:before {
  border: 28px solid #13f6c1;
  border-image: url(../images/buybg.svg) 25;
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: -1;
}
/* Order confirmation css */
/* 29-03-2024 End CSS */

/* 01-04-2024 */

/* .reg__checkout--number .reg__plate--data {
  padding: 18px 15px;
  max-width: 528px;
}
.reg__list--data input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media(max-width:768px){
  .reg__car--details svg {
      margin-bottom: 0px;
  }
}

@media(max-width:480px){
  .reg__hero--section .reg__buy--button button svg {
    margin-left: 180px;
    margin-right: 0px;
  }
} */

.reg__checkout--number .reg__plate--data {
  padding: 10px 15px;
  max-width: 328px;
}

.reg__buy--button {
  margin-bottom: 20px !important;
}

/* .reg__list--data input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
} */

.reg__enquiry__form .cher__no--plate h2 {
  width: 100%;
}
.reg__list--data form .form-check {
  display: flex;
}

@media (max-width: 992px) {
  .reg__enquiry--section .reg__data--section.reg__enquiry__form {
    flex-direction: column-reverse !important;
  }
  /* //.genrator__menu--section .row {
  //	flex-direction: column-reverse !important;
  //} */
  .offer__data .genrator__menu--inner.col-lg-5 {
    margin-left: auto;
    margin-right: auto;
  }
  /* .reg__data--section.reg__enquiry__form.row {
    flex-direction: column-reverse;
  } */
}

@media (max-width: 768px) {
  .reg__car--details svg {
    margin-bottom: 0px;
  }
  .row.enquiry__form .col-12 {
    margin-top: 10px !important;
    margin-bottom: 10px;
  }
  .enquiry__form ul.mb-20 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .reg__hero--section .reg__buy--button button svg {
    /* margin-left: 180px; */
    margin-right: 0px;
  }
}

/* Country code */
input.form-control.number_code {
  max-width: 36px;
  padding: 0;
  border: 0;
  font-size: 14px;
  text-align: center;
}
.dropdown-toggle:after {
  background-image: url(../images/menuarrow.svg);
  background-size: 100% 100%;
  width: 14px;
  height: 14px;
  border: none;
  vertical-align: text-top;
  margin-left: 0.7em;
}
.plate__type.py-4 .form-check {
  display: block;
}
/* 02-04-2024 Start CSS */
.fnumber .input-group-text {
  min-width: 50px;
  justify-content: center;
}
.form-check input[type="radio"] {
  margin-top: 4px;
  /* background-color: initial; */
}
.form-check input[type="radio"]:checked {
  margin-top: 4px;
  background-color: #1a3057 !important;
}
/* 02-04-2024 End CSS */
/* 03-04-2024 Start CSS */
.reg__checkout--number .reg__plate--data h1 {
  font-size: 56px;
}

@media (max-width: 991px) {
  .genrator__menu--section .row.justify-between {
    flex-direction: column-reverse !important;
  }
  .reg__help--form,
  .reg__list--data {
    margin: auto;
  }
}
/* 03-04-2024 End CSS */
/* 04-04-2024 Start CSS */
.reg__orderconfirm--email h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}
/* 04-04-2024 End CSS */
/* 05-04-2024 Start CSS */
.flex-1{
  flex:1;
}
.reg__orderplate--form .reg__list--data {
  margin-inline: auto;
}
.btn_bord {
  border-width: 0px;
  background-color: transparent;
}
@media(max-width: 1400px){
  .reg__enquiry--data {
    background-position: 95% 100%;
  }
}
@media(min-width: 1200px) and (max-width: 1300px){
  .reg__enquiry--data {
    background-position: 85% 100%;
  }
  
}
@media(max-width: 991px){
  .offer__data {
    margin-top: 50px;
}
}
/* 05-04-2024 End CSS */
/* 08-04-2024 Strat CSS */
.number__btn:before {
	content: ' ';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;
	width: 100%;
	height: 80px;
}

.reg__cherished--inner-data {
	position: relative;
}

.reg__contact--inner iframe {
	width: 100%;
}

.accordion .accordion-item .accordion-header button{
	font-family:
		Yukita Sans,
		'sans-serif !important';
}

.reg__help--form .alert {
	font-family: Silka, 'sans-serif';
}
.reg__help--form .alert span {
	font-size: 16px !important;
}

body.modal-open header {
	z-index: 99;
}

.accordion-body p a {
	font-size: 16px;
	border-bottom: 1px solid;
}

.plateOrder h2 {
	width: 100%;
}
.reg__cherished--data.header-bg {
	background: #437dc066 !important;
}

.fsubmit_btn button {
	white-space: nowrap;
}

.reg__plate--vivewer .reg__buy--button.download__button button:before {
	border: 28px solid #00f5d0;
	border-image: url(../images/buybg.svg)
		25;
}

.fsubmit_btn button:before {
	border: 33.5px solid #00f5d0;
	border-image: url(../images/fsubbtn.svg)
		35.5;
}
.form-check input[type=radio] {
  margin-top: 2px !important;
}

@media (max-width: 400px) {
	.offer-btn button:before {
		border: 30px solid #1a3057;
		border-image: url(../images/mobg.svg) 33;
	}
	.offer-btn button {
		font-size: 12px;
	}
	button.reg__sell--submit {
		font-size: 16px;
	}
	.reg__sell--data .ml-25 {
		margin-left: 0;
	}
	.ml-25.mb-40.disc {
		margin-left: 15px;
	}
	.header .navbar-brand img {
		width: 130px;
	}
	span.navbar-toggler-icon {
		width: 1.5rem !important;
		height: 1.5rem !important;
	}
}

@media (max-width: 576px) {
	.reg__hero--section .reg__car--title input {
		/* font-size: 20px; */
		padding: 8px 10px !important;
	}
	.reg__call--details a {
		font-size: 16px;
	}
}

@media (max-width: 992px) {
	.reg__sell--number.reg__valuation--data .row:first-child {
		flex-direction: column;
	}
	.reg__sell--number.reg__valuation--data .row:first-child {
		flex-direction: row;
	}
	section.genrator__menu--section.reg__plate--vivewer .reg__help--form {
		order: 2 !important;
	}
	.genrator__menu--section.offer__data .row.justify-between {
		flex-direction: revert !important;
	}
	.reg__contact--form {
		margin-top: 20px;
	}
	.reg__sell--data {
		margin-bottom: 30px;
	}
	.reg__list--data {
		max-width: 100%;
	}
	.offer-btn a {
		margin-bottom: 0;
	}
	.reg__contact--inner {
		/* margin: 20px auto auto 0; */
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
	.offer__data .reg__help--form.reg__contact--form {
		width: 100%;
		max-width: 100%;
	}
}
.form-check input[type=radio] {
  margin-top: 2px !important;
}
/* 08-04-2024 End CSS */
/* 09-04-2024 START CSS */
.car-inner p p{
  width: 100%;
}

/* 09-04-2024 END CSS */
.reg__checkout-main .offer-btn a {
  margin-top: 15px;
}

/* 16- 04 - 2024  */

.offer-btn.process-btn button img {
  -webkit-filter: invert(100%); 
 filter: invert(100%);
 transition: all 0.5s ease-in-out;
}
.offer-btn.process-btn button:hover img{
 filter: none;
}

@media (max-width: 991px) {
.reg__plate--vivewer .row.justify-between {
 flex-direction: row-reverse !important;
}
.reg__sell--number.reg__valuation--data .row:first-child{
    flex-direction: column !important;
    gap: 20px;
}
}
.lg__about-info--section .reg__about--imagedata .img-fluid {
  width: auto;
  height: 130px;
  }

  /* 22-02-2024*/
  form.reg__mobile--search {
    display: none !important;
}
@media(max-width: 991px){
form.reg__mobile--search {
    display: flex !important;
    max-width: 510px;
    margin-top: 20px;
    width: 100%;
    margin-inline: auto;
}
.reg__sell--number .row .col-12.col-lg-6 + .col-12.col-lg-6 {
  order: 1;
}

.reg__sell--number .row .col-12.col-lg-6 {
  order: 2;
  margin-bottom: 30px;
}
.reg__valuation--heading .reg__enquiry--data h1, .reg__valuation--heading .reg__enquiry--data h2 {
  flex: auto;
}
}

/* @media(max-width:610px){
    .reg__mobile--search input {
    padding-bottom: 25px;
}
} */
@media(max-width:380px){
    .reg__mobile--search input {
    /* padding-bottom: 20px; */
    font-size: 18px;
}
}
.reg__buypage form input{
    /* max-width: 450px; */
    width: 100%;
}
.reg__mobile--search input {
    max-width: 450px;
    width: 100%;
}
.reg__car--details svg {
    width: 100%;
    height: auto;
}
.reg__confirm--box input[type=checkbox] {
  width: 18px;
  height: 18px;
}
.reg__confirm--box input[type=checkbox]:checked {
  background-color: #13f6c1;
  border-color: #13f6c1;
}

  /* 22-02-2024 */
  .reg__buypage form span,
.reg__mobile--search span {
    width: 100%;
}
.reg__buypage form input,
.reg__mobile--search span input {
    height: 100%;
}
.reg__buypage form span.custom-input-container,
.reg__mobile--search span.custom-input-container {
  position: relative;
}
.reg__buypage form span.custom-input-container::before,
.reg__mobile--search span.custom-input-container::before {
  color: #8f8f8f;
  content: "Search names, initials, numbers, words or anything…";
    font-family: Silka;
    font-size: 16px;
    font-weight: 400;
    height: fit-content;
    left: 15px;
    line-height: 16px;
    padding-right: 10px;
    pointer-events: none;
    position: absolute;
    text-align: start;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 15px);
    /* z-index: 99999; */
  }
  @media (max-width: 768px) {
  .reg__enquiry__form .reg__car--details {
    padding: 20px 0px 50px;
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .reg__buypage form span.custom-input-container::before,
  .reg__buypage input::placeholder,
  .reg__mobile--search span.custom-input-container::before {
      font-size: 12px;
  }
}
.grecaptcha-badge {
  visibility: hidden !important;
}
.reg__payment--data .payment__image:last-child {
  width: 132px;
}

.reg__payment--data .payment__image img {
  width: 100%;
}
input:checked[type=checkbox]:checked {
  background-color: #13f6c1;
  border-color: #13f6c1 !important;
}
/* 13-05-2024 Start CSS */
@media (min-width: 991px) and (max-width: 1280px){
  .reg__buypage .reg__search--button, .header .reg__search--button {
    border-top-right-radius: 45% !important;
}
}
@media(max-width: 600px){
  .reg__buypage .reg__search--button, .header .reg__search--button {
    border-top-right-radius: 40% !important;
}
}
/* 13-05-2024 Ens CSS */

.list_style {
  list-style: disc !important;
}

.link_site {
  font-size: 16px;
  border-bottom: 1px solid;
}
/* 15-05-2024 Strat CSS */
/* .plate__type li {
    list-style-type: decimal;
}

.plate__type .form-check.f18 {
    padding-left: 00px;
} */
/* 15-05-2024 End CSS */
@media(max-width: 350px){
  .plateOrder h2.f60 {
    font-size: 40px;
}
}